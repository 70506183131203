import {Footer} from "../Components/Footer";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Tabs from "../Components/Tabs/Tabs";
import {
    BlacklistRequest,
    Challenge,
    CreateChallengeRequest,
    EditChallengeRequest, Giveaway,
    PlayerDetails,
    StaticList
} from "../API/api";
import {axiosGet, axiosPost, axiosPut} from "../Utility/httpClient";
import {dialogStyles} from "./Players";
import {
    Checkbox,
    Dialog,
    FormControl,
    FormControlLabel,
    InputLabel,
    ListItemText, MenuItem,
    OutlinedInput,
    Select
} from "@mui/material";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    card: {
        minWidth: 100,
        maxHeight: 100,
        height: "100%",
        backgroundColor: 'transparent !important',
        margin: '15px',
        border: 'none',
        boxShadow: 'none',
    },
    cardContent: {
        backgroundSize: 'cover',
        backgroundColor: '#080808',
        height: '56px',
        border: '2px solid #2a2e38',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
        background: '#1b1d27 !important'
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
}));

export type LandingProps = {
    userDetails: PlayerDetails | undefined
};


export interface ModalProps {
    open: boolean;
    onClose: () => void;
}

export type GiveawayModalProps = {
    editing?: Giveaway
    setGiveaways: any
} & ModalProps

export type BlacklistModalProps = {
    strings: string
    title: string
    endpoint: string
} & ModalProps

export type ChallengeModalProps = {
    editing?: Challenge
    setItems: any;
} & ModalProps

export function EditBlacklistModal(props: BlacklistModalProps) {
    const { onClose, open, title, endpoint } = props;

    const [text, setText] = useState<string>(props.strings);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const editBlacklist = async (request: BlacklistRequest): Promise<StaticList> => axiosPost(endpoint, request)

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
            <h2>{title}</h2>
            <div className="Btcs-form">
                <div className="Form-input-field">
                    <h6>List</h6>
                    <div className="Form-input">
                        <textarea defaultValue={text} onChange={e => setText(e.target.value)} />
                    </div>
                </div>
                <div className="Form-submission">
                    <button className="Alt" onClick={() => {
                        if (submitting) {
                            return
                        }
                        if (!text) {
                            return
                        }
                        setSubmitting(true)
                        editBlacklist({
                            list: text
                        }).then(r => {
                            window.location.reload()
                        })
                    }}>{submitting ? 'Submitting...' : 'Save'}</button>
                </div>
            </div>
        </Dialog>
    );
}

function AddChallengeModal(props: ChallengeModalProps) {
    const classes = useStyles();
    const { onClose, open, setItems } = props;

    const [enums, setEnums] = useState<Array<string>>([]);
    const [name, setName] = useState<string | undefined>(undefined);
    const [game, setGame] = useState<string | undefined>(undefined);
    const [image, setImage] = useState<string | undefined>(undefined);
    const [enumVal, setEnumVal] = useState<string | undefined>(undefined);
    const [end, setEnd] = useState<string | undefined>(undefined);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const getEnums = async (): Promise<Array<string>> => axiosGet(`/general/challenge-enums`)
    const createChallenge = async (request: CreateChallengeRequest): Promise<Array<Challenge>> => axiosPost(`/moderate/create-challenge`, request)
    const editChallenge = async (request: EditChallengeRequest): Promise<Array<Challenge>> => axiosPut(`/moderate/edit-challenge`, request)

    const handleClose = () => {
        onClose();
    };

    useEffect(() => {
        getEnums().then(r => setEnums(r))
        if (props.editing) {
            setName(props.editing.name)
            setGame(props.editing.game)
            setImage(props.editing.image)
            setEnumVal(props.editing.challengeEnum)
            setEnd(props.editing.endDate)
        }
    }, [])
    const categories = [ 'Select Game', 'DICE', 'FLOWER', 'CASKET', 'HOT_COLD' ]

    const handleCategoryChange = (event: any) => {
        const {
            target: { value },
        } = event;
        setGame(value)
    };
    const handleEnumChange = (event: any) => {
        const {
            target: { value },
        } = event;
        setEnumVal(value)
    };
    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
            <h2>{props.editing ? 'Edit' : 'Create'} Challenge</h2>
            <div className="Btcs-form">
                <div className="Form-input-field">
                    <h6>Challenge Name</h6>
                    <div className="Form-input">
                        <div className="Form-icon">
                            <img src="/form/name.png"/>
                        </div>
                        <input type="text" placeholder="Challenge Name..." defaultValue={name}
                               onChange={(e) => {
                                   setName(e.target.value)
                               }}/>
                    </div>
                </div>
                <div className="Form-input-field">
                    <div className="Form-input ModalSelect">
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-multiple-checkbox-label" sx={{ color: 'white' }}>Game</InputLabel>
                            <Select
                                key={"select-game"+game}
                                labelId="demo-multiple-checkbox-label"
                                className={classes.select}
                                inputProps={{
                                    classes: {
                                        icon: classes.icon,
                                        root: classes.root,
                                    },
                                }}
                                id="demo-multiple-checkbox"
                                value={game}
                                onChange={handleCategoryChange}
                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#2a2e38',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#2a2e38',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#2a2e38',
                                    },
                                    '.MuiSvgIcon-root ': {
                                        fill: "white !important",
                                    }
                                }}

                                input={<OutlinedInput sx={{ color: 'white' }}
                                                      key={"input-game"+game}
                                                      label="Game" />}
                                renderValue={(selected) => selected}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 48 * 4.5 + 8,
                                            width: 250,
                                            color: '#acacac',
                                            background: '#121418'
                                        },
                                    },
                                }}
                            >
                                {
                                    categories.map((variant) => (
                                        //@ts-ignore
                                        <MenuItem key={variant.id} value={variant}>
                                            <ListItemText primary={variant} />
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>Challenge Image</h6>
                    <div className="Form-input">
                        <div className="Form-icon">
                            <img src="/form/img.png"/>
                        </div>
                        <input type="text" placeholder="Challenge Image..." defaultValue={image}
                               onChange={(e) => {
                                   setImage(e.target.value)
                               }}/>
                    </div>
                </div>
                <div className="Form-input-field">
                    <div className="Form-input ModalSelect">
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-multiple-checkbox-label" sx={{ color: 'white' }}>Enum</InputLabel>
                            <Select
                                key={"select-enum"+enumVal}
                                labelId="demo-multiple-checkbox-label"
                                className={classes.select}
                                inputProps={{
                                    classes: {
                                        icon: classes.icon,
                                        root: classes.root,
                                    },
                                }}
                                id="demo-multiple-checkbox"
                                value={enumVal}
                                onChange={handleEnumChange}
                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#2a2e38',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#2a2e38',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#2a2e38',
                                    },
                                    '.MuiSvgIcon-root ': {
                                        fill: "white !important",
                                    }
                                }}

                                input={<OutlinedInput sx={{ color: 'white' }}
                                                      key={"input-enum"+enumVal}
                                                      label="Enum" />}
                                renderValue={(selected) => selected}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 48 * 4.5 + 8,
                                            width: 250,
                                            color: '#acacac',
                                            background: '#121418'
                                        },
                                    },
                                }}
                            >
                                {
                                    enums.map((variant) => (
                                        //@ts-ignore
                                        <MenuItem key={variant.id} value={variant}>
                                            <ListItemText primary={variant} />
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>Challenge End</h6>
                    <div className="Form-input">
                        <input type="datetime-local" defaultValue={end}
                               onChange={(e) => {
                                   setEnd(e.target.value)
                               }}/>
                    </div>
                </div>
                <div className="Form-submission">
                    <button onClick={() => {
                        if (submitting) {
                            return
                        }
                        if (!name || !game || !image || !enumVal) {
                            return
                        }
                        setSubmitting(true)
                        if (props.editing) {
                            editChallenge({
                                id: props.editing.id,
                                name: name,
                                game: game,
                                image: image,
                                enum: enumVal,
                                end: end,
                            }).then(response => {
                                setSubmitting(false)
                                setName(undefined)
                                setGame(undefined)
                                setImage(undefined)
                                setEnumVal(undefined)
                                setItems(response)
                                onClose()
                            });
                        } else {
                            createChallenge({
                                name: name,
                                game: game,
                                image: image,
                                enum: enumVal,
                                end: end,
                            }).then(response => {
                                setSubmitting(false)
                                setName(undefined)
                                setGame(undefined)
                                setImage(undefined)
                                setEnumVal(undefined)
                                setItems(response)
                                onClose()
                            });
                        }
                    }}>{submitting ? 'Submitting...' : (props.editing ? 'Save' : 'Create') + ' Challenge'}</button>
                </div>
            </div>
        </Dialog>
    );
}

export const AdminChallenges: React.FC<LandingProps> = ({ userDetails }) => {
    const classes = useStyles();

    const getChallenges = async (): Promise<Array<Challenge>> => axiosGet(`/moderate/challenges`)
    const deleteChallenge = async (id: string): Promise<Array<Challenge>> => axiosPut(`/moderate/delete-challenge`, {uuid: id})
    const getChallengerPrizes = async (): Promise<StaticList> => axiosGet(`/moderate/challenger-prizes`)
    const getChallengersBlacklist = async (): Promise<StaticList> => axiosGet(`/moderate/challengers-blacklist`)

    const [challenges, setChallenges] = useState<Array<Challenge>>([]);
    const [editing, setEditing] = useState<Challenge | undefined>(undefined);
    const [open, setOpen] = React.useState(false);
    const [prizes, setPrizes] = useState<StaticList>({strings:''});
    const [openPrizes, setOpenPrizes] = React.useState(false);
    const [blacklist, setBlacklist] = useState<StaticList>({strings:''});
    const [openBlacklist, setOpenBlacklist] = React.useState(false);

    useEffect(() => {
        getChallengerPrizes().then((response: StaticList) => {
            setPrizes(response)
        })
        getChallengersBlacklist().then((response: StaticList) => {
            setBlacklist(response)
        })
    }, [])

    const handleClose = () => {
        setOpen(false);
        // setEditing(undefined);
    };

    useEffect(() => {
        getChallenges().then((response: Array<Challenge>) => {
            setChallenges(response)
        })
    }, [])


    const prizeNames = prizes?.strings?.toString().split(',') ?? ['']
    const blacklistNames = blacklist?.strings?.toString().split(',') ?? ['']

    // window.alert(prizes.strings)

    return (<>
        <div className="App-contents">
            <div className="Landing-content">

                <div className="Section-heading">
                    <h4>Challenges</h4>
                </div>

                <div className="Section-heading">
                    <button onClick={() => setOpen(true)}>Add Challenge</button>
                </div>

                {/* @ts-ignore */}
                <Tabs>
                    {/* @ts-ignore */}
                    <div label="Current">
                        <div className="Btcs-table">
                            <ul className="Header">
                                <li>Name</li>
                                <li>Date</li>
                                <li>Action</li>
                            </ul>
                            {
                                challenges.filter(challenge => !challenge.winnerName).map(challenge => {
                                    return <ul className="Entry">
                                        <li>{challenge.name}</li>
                                        <li>{moment(challenge.creationDate).fromNow()}</li>
                                        <li className="Actions">

                                            <button onClick={() => {
                                                setEditing(challenge)
                                                setOpen(true)
                                            }}>Edit</button>

                                            <button onClick={() => {
                                                deleteChallenge(challenge.id).then(r => {
                                                    setChallenges(r)
                                                })
                                            }}>Delete</button></li>
                                    </ul>
                                })
                            }
                        </div>
                    </div>
                    {/* @ts-ignore */}
                    <div label="Ended">
                        <div className="Btcs-table">
                            <ul className="Header">
                                <li>Name</li>
                                <li>Date</li>
                                <li>Action</li>
                            </ul>
                            {
                                challenges.filter(challenge => challenge.winnerName).map(challenge => {
                                    return <ul className="Entry">
                                        <li>{challenge.name}</li>
                                        <li>{moment(challenge.creationDate).fromNow()}</li>
                                        <li className="Actions">

                                            <button onClick={() => {
                                                setEditing(challenge)
                                                setOpen(true)
                                            }}>Edit</button>

                                            <button onClick={() => {
                                                deleteChallenge(challenge.id).then(r => {
                                                    setChallenges(r)
                                                })
                                            }}>Delete</button></li>
                                    </ul>
                                })
                            }
                        </div>
                    </div>
                    {/* @ts-ignore */}
                    <div label="Prizes">

                        <div className="Section-heading">
                            <button onClick={() => setOpenPrizes(true)}>Edit</button>
                        </div>

                        <div className="Blacklist">
                            <ul>
                                {
                                    prizeNames.map(name => <li>{name}</li>)
                                }
                            </ul>
                        </div>

                    </div>
                    {/* @ts-ignore */}
                    <div label="Blacklist">

                        <div className="Section-heading">
                            <button onClick={() => setOpenBlacklist(true)}>Edit</button>
                        </div>

                        <div className="Blacklist">
                            <ul>
                                {
                                    blacklistNames.map(name => <li>{name}</li>)
                                }
                            </ul>
                        </div>

                    </div>
                </Tabs>


                <EditBlacklistModal
                    key={prizes.strings}
                    open={openPrizes}
                    onClose={handleClose}
                    strings={prizes.strings}
                    title={"Edit Challengers Prize List"}
                    endpoint={`/moderate/modify-challenger-prizes`}
                />

                <EditBlacklistModal
                    key={blacklist.strings}
                    open={openBlacklist}
                    onClose={handleClose}
                    strings={blacklist.strings}
                    title={"Edit Challengers Blacklist List"}
                    endpoint={`/moderate/modify-challengers-blacklist`}
                />

                <AddChallengeModal
                    key={editing ? editing.id : 'no-id-challenge'}
                    open={open}
                    onClose={handleClose}
                    editing={editing}
                    setItems={setChallenges}
                />


                <Footer/>
            </div>
        </div>
    </>)
}