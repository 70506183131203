import React, {useEffect, useState} from "react";
import '../Assets/CSS/Admin.scss';
import {BotDTO, EarningsDay, Player, PlayerDetails, PlayerVerbose, WagerDTO} from "../API/api";
import {axiosGet,axiosPost} from "../Utility/httpClient";
import {makeStyles} from "@material-ui/core/styles";
import {BotWagersTable} from "../Components/BotWagersTable";
import {ArrowBack} from "@material-ui/icons";
import {CasinoUsersTable} from "../Components/CasinoUsersTable";
import {Chart} from "../Components/Chart";
import {formatDateTimeYear, toRuneScapeFormatFromExact} from "../Utility/utils";
import moment from "moment";
import {PlayerWagersTable} from "../Components/PlayerWagersTable";
import {PlayerWagersTableMobile} from "../Components/PlayerWagersTableMobile";
import {Footer} from "../Components/Footer";
import ConfirmActionModal from "../Components/ConfirmActionModal";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
        background: '#202429 !important'
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
    chip: {
        color: 'white !important',
        background: '#121418 !important',
        backgroundColor: '#121418 !important',
        border: '1px solid #2a2e38 !important',
        cursor: 'pointer !important'
    },
    card: {
        minWidth: 100,
        maxHeight: 100,
        height: "100%",
        backgroundColor: 'transparent !important',
        margin: '15px',
        border: 'none',
        boxShadow: 'none',
    },
    chipSelected: {
        backgroundSize: 'cover',
        height: '50px',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
        cursor: 'pointer !important',
        backgroundColor: '#080808',
        border: '3px solid #886CFF !important',
    },
    cardContent: {
        backgroundSize: 'cover',
        backgroundColor: '#080808',
        height: '56px',
        border: '2px solid #2a2e38',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
    },
}));

export const dialogStyles = {
    backgroundColor: '#1a1d23',
    color: 'white',
    padding: '25px',
    boxShadow: 'none',
};

const searchApi = async (username: string): Promise<Array<Player>> => axiosGet(`/admin/players?username=${username.replaceAll(" ", "_")}`)
const getPlayer = async (username: string): Promise<PlayerVerbose> => axiosGet(`/admin/player?username=${username.replaceAll(" ", "_")}`)
const resetRakeback = async (username: string): Promise<PlayerVerbose> => axiosPost(`/admin/reset-rakeback`, {username: username})
const getPlayerEarnings = async (username: string): Promise<Array<EarningsDay>> => axiosGet(`/admin/player-earnings?username=${username.replaceAll(" ", "_")}`)

export type BotsProps = {
    userDetails: PlayerDetails | undefined
};

export const Players: React.FC<BotsProps> = ({ userDetails }) => {

    const [source, setSource] = React.useState<Array<Player>|undefined>(undefined)
    const [searching, setSearching] = React.useState<boolean>(false)
    const [user, setUser] = React.useState<Player|undefined>(undefined)
    const [playerEarnings, setPlayerEarnings] = React.useState<Array<EarningsDay>>([])
    const [playerDetails, setPlayerDetails] = React.useState<PlayerVerbose|undefined>(undefined)
    const [activeIndex, setActiveIndex] = React.useState<number>(0)
    const [searchTerm, setSearchTerm] = React.useState<string | undefined>(undefined)
    const [confirmAction, setConfirmAction] = useState(() => () => console.log("default ooops"));
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const classes = useStyles();

    useEffect(() => {
        if (!searching && userDetails && searchTerm && searchTerm.length >= 3) {
            setSearching(true)
            searchApi(searchTerm.replaceAll(' ', '_')).then(r => {
                setSearching(false)
                setSource(r)
            })
        }
    }, [userDetails, searchTerm])

    const loadAndSetUser = (user: BotDTO) => {
        setUser(user)
        getPlayer(user.username).then(r => setPlayerDetails(r))
        getPlayerEarnings(user.username).then(r => setPlayerEarnings(r))
    }

    function getView(user: Player | undefined, source: Array<Player> | undefined, activeIndex: number, setActiveIndex: any, setUser: any): JSX.Element {

        if (user) {
            if (playerDetails) {
                return (<>
                    <div className="Metrics-container">
                        <div className="Section-heading">
                            <h4 style={{textAlign: 'left'}}><ArrowBack onClick={() => { setUser(undefined) }} width={80} style={{marginRight: '10px'}} />{playerDetails.username}</h4>
                        </div>
                    </div>

                    <div className="homeWidgets">
                        <div className="widgetSm">
                            <ul className="widgetSmList">
                                <ul>
                                    <li>Username</li><li>{playerDetails.username}</li>
                                </ul>
                                <ul>
                                    <li>Member Since</li><li>{formatDateTimeYear(playerDetails.since)}</li>
                                </ul>
                                <ul>
                                    <li>Last Seen</li><li>{moment(playerDetails.seen).fromNow()}</li>
                                </ul>
                                <ul>
                                    <li>Win Streak</li><li>{playerDetails.streak}</li>
                                </ul>
                                <ul>
                                    <li>Total Wagers</li><li>{playerDetails.totalWagers}</li>
                                </ul>
                                <ul>
                                    <li>Total Wagered</li><li>{toRuneScapeFormatFromExact(playerDetails.totalWagered)} GP</li>
                                </ul>
                                <ul>
                                    <li>Net Profit</li><li>{toRuneScapeFormatFromExact(playerDetails.totalEarned)} GP</li>
                                </ul>
                                <ul>
                                    <li>Total Rakeback</li><li>{toRuneScapeFormatFromExact(playerDetails.totalRakeback ?? 0)} GP</li>
                                </ul>
                                <ul>
                                    <li>Rakeback Owed</li><li>{toRuneScapeFormatFromExact(playerDetails.rakeback ?? 0)} GP</li>
                                </ul>
                                <ul>
                                    <li>
                                        <br />
                                        <button onClick={() => {
                                                setConfirmAction(() => () => {
                                                    resetRakeback(playerDetails.username).then(response => {
                                                        setPlayerDetails(response)
                                                    })
                                                })
                                                setOpenConfirm(true)
                                            }
                                        }>Reset Rakeback</button>
                                    </li>
                                </ul>

                            </ul>
                        </div>

                        <div className="widgetSm" style={{minWidth: '800px'}}>
                            <span className="widgetSmTitle">Earnings</span>
                            <ul className="widgetSmList">
                                <Chart data={playerEarnings ?? []}/>
                            </ul>
                        </div>


                    </div>

                    <div className="homeWidgets">
                        <div className="widgetSm" style={{minWidth: '100%'}}>
                            <span className="widgetSmTitle">Wager History</span>
                            <ul className="widgetSmList">

                                <div className="Navigation-Items-Visible">
                                    <PlayerWagersTable rows={playerDetails.wagers} user={user} />
                                </div>
                                <div className="Navigation-Items-Mobile">
                                    <PlayerWagersTableMobile rows={playerDetails.wagers} user={user} />
                                </div>

                            </ul>
                        </div>

                    </div>


                <ConfirmActionModal
                    open={openConfirm}
                    onClose={() => setOpenConfirm(false)}
                    action={confirmAction}
                />

                </>)
            }
            return (<div>

                <div className="Section-heading">
                    <h4>Loading...</h4>
                </div>

            </div>);
        }
        const keyword = searchTerm && searchTerm.length >= 3 ? searchTerm : 'Enter a username to search for'
        return (<>

            <div className="homeWidgets">
                <h3>{keyword}</h3>
            </div>

                <div className="Search-bar">
                    <div className="Bar-content">
                        <svg id="Layer_1" width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path
                                d="M8.5 3a5.5 5.5 0 0 1 4.38 8.82l4.15 4.15a.75.75 0 0 1-.98 1.13l-.08-.07-4.15-4.15A5.5 5.5 0 1 1 8.5 3Zm0 1.5a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
                                fill="currentColor"/>
                        </svg>
                        <input type="text" defaultValue={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search for a player..." />
                    </div>
                </div>

            <div className="homeWidgets">
                <div className="widgetLg" style={{  minWidth: '100%', padding: '10px'}}>
                    <h3 className="widgetLgTitle" style={{  margin: '10px 0'}}>Players</h3>
                    <CasinoUsersTable rows={(source ?? []).filter(r => searchTerm == undefined || r.username.toLowerCase().includes(searchTerm!!.toLowerCase()))} setUser={loadAndSetUser} />
                </div>
            </div>
            <br /><br /><br /><br />
        </>)
    }

    if (userDetails) {

        return (<>
            <div className="App-contents">
                <div className="Landing-content">
                    <br/><br/>
                    {getView(user, source, activeIndex, setActiveIndex, setUser)}
                    <Footer/>
                </div>
            </div>
        </>)
    }
    return (<div>

        <div className="Section-heading">
            <h4>Loading...</h4>
        </div>

    </div>);
}