import React from "react";
import Dialog from "@mui/material/Dialog";
import {ModalProps} from "../Routes/AdminChallenges";

export type ConfirmModalProps = {
    action: () => void
} & ModalProps;

export default function ConfirmActionModal(props: ConfirmModalProps) {
    const { onClose, open, action } = props;

    const dialogStyles = {
        backgroundColor: '#1b1d21',
        color: 'white',
        padding: '25px',
        boxShadow: 'none',
        width: '300px',
        overflow: 'hidden',
        borderRadius: '30px'
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
            <div className="Btcs-form Confirm-Modal">

                <p style={{marginTop: '50px'}}>
                    This action <span>cannot</span> be undone!
                </p>
                <p>
                    Are you <span>sure</span> you wish to <span>continue</span>?
                </p>

                <button className={"Alt"} onClick={() => handleClose()} style={{marginTop: '10px'}}>Back</button>
                <button onClick={() => { action(); handleClose() } } style={{marginTop: '10px'}}>Proceed</button>

            </div>

        </Dialog>
    );
}