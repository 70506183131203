import {createEditor, Node} from 'slate'
import {Editable, Slate, withReact} from 'slate-react'
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {CreateGiveawayRequest, EditGiveawayRequest, Giveaway, PlayerDetails} from "../API/api";
import {axiosGet, axiosPost, axiosPut} from "../Utility/httpClient";
import ConfirmActionModal from "../Components/ConfirmActionModal";
import {GiveawayModalProps} from "./AdminChallenges";
import {Dialog} from "@mui/material";
import {dialogStyles} from "./Bots";
import {serialize} from "v8";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    card: {
        minWidth: 100,
        maxHeight: 100,
        height: "100%",
        backgroundColor: 'transparent !important',
        margin: '15px',
        border: 'none',
        boxShadow: 'none',
    },
    cardContent: {
        backgroundSize: 'cover',
        backgroundColor: '#080808',
        height: '56px',
        border: '2px solid #2a2e38',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
        background: '#1b1d27 !important'
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
}));


const createGiveaway = async (request: CreateGiveawayRequest): Promise<Array<Giveaway>> => axiosPost(`/moderate/create-giveaway`, request)
const editGiveaway = async (request: EditGiveawayRequest): Promise<Array<Giveaway>> => axiosPut(`/moderate/edit-giveaway`, request)

function GiveawayModal(props: GiveawayModalProps) {
    const { onClose, open, setGiveaways } = props;
    const classes = useStyles();

    const [name, setName] = useState<string | undefined>(undefined);
    const [image, setImage] = useState<string | undefined>(undefined);
    const [description, setDescription] = useState<string | undefined>(undefined);
    const [link, setLink] = useState<string | undefined>(undefined);
    const [end, setEnd] = useState<string | undefined>(undefined);
    const [submitting, setSubmitting] = useState<boolean>(false);

    const [editor] = useState(() => withReact(createEditor()))

    useEffect(() => {
        if (props.editing) {
            setName(props.editing.name)
            setImage(props.editing.image)
            setDescription(props.editing.description)
            setLink(props.editing.link)
            setEnd(props.editing.end)
        }
    }, [])

    const handleClose = () => {
        onClose();
    };
    var initialValue: any = [
        {
            type: 'paragraph',
            children: [{ text: props.editing ? props.editing.description : 'Description...' }],
        },
    ]

    const serialize = (nodes: any) => {
        return nodes.map((n: any) => Node.string(n)).join('\n')
    }

    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
            <h2 className="ModalHeading">Add Giveaway</h2>
            <div className="Btcs-form">
                <div className="Form-input-field">
                    <h6>Giveaway Name</h6>
                    <div className="Form-input">
                        <input type="text" placeholder={"Giveaway Name..."} defaultValue={name}
                               onChange={(e) => {
                                   setName(e.target.value)
                               }}/>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>Image URL</h6>
                    <div className="Form-input">
                        <input type="text" placeholder="Image..." defaultValue={image}
                               onChange={(e) => {
                                   setImage(e.target.value)
                               }}/>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>Giveaway Link</h6>
                    <div className="Form-input">
                        <input type="text" placeholder={"Giveaway Link..."} defaultValue={link}
                               onChange={(e) => {
                                   setLink(e.target.value)
                               }}/>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>Giveaway Description</h6>
                    <div className="Form-input">
                        <Slate editor={editor} initialValue={initialValue} onChange={des => {
                            setDescription(serialize(des))
                        }}>
                            <Editable />
                        </Slate>
                    </div>
                </div>
                <div className="Form-input-field">
                    <h6>Giveaway End</h6>
                    <div className="Form-input">
                        <input type="datetime-local" defaultValue={end}
                               onChange={(e) => {
                                   setEnd(e.target.value)
                               }}/>
                    </div>
                </div>
                <div className="Form-submission">
                    <button className="Alt" onClick={() => {
                        if (submitting) {
                            return
                        }
                        if (!name || !image || !description) {
                            return
                        }
                        setSubmitting(true)
                        if (props.editing) {
                            editGiveaway({
                                id: props.editing.id,
                                name: name!,
                                image: image!,
                                description: description!,
                                link: link!,
                                end: end!,
                            }).then(r => {
                                setSubmitting(false)
                                handleClose()
                                setGiveaways(r)
                            })
                        } else {
                            createGiveaway({
                                name: name!,
                                image: image!,
                                description: description!,
                                link: link,
                                end: end,
                            }).then(r => {
                                setSubmitting(false)
                                handleClose()
                                setGiveaways(r)
                            })
                        }
                    }}>{(submitting ? 'Submitting...' : (props.editing ? 'Save' : 'Add')) + 'Giveaway'}</button>
                </div>
            </div>
        </Dialog>
    );
}


export type GiveawaysAdminProps = {
    userDetails: PlayerDetails | undefined
};

export const AdminGiveaways: React.FC<GiveawaysAdminProps> = ({ userDetails }) => {
    const classes = useStyles();
    const getGiveaways = async (): Promise<Array<Giveaway>> => axiosGet(`/moderate/giveaways`)
    const deleteGiveaway = async (id: string): Promise<Array<Giveaway>> => axiosPut(`/moderate/delete-giveaway`, {uuid: id})

    const [editing, setEditing] = useState<Giveaway | undefined>(undefined);
    const [giveaways, setGiveaways] = useState<Array<Giveaway>>([]);
    const [open, setOpen] = React.useState(false);
    const [openDelete, setOpenDelete] = React.useState(false);
    const [confirmAction, setConfirmAction] = useState(() => () => console.log("default ooops"));

    const handleClose = () => {
        setOpen(false);
        setEditing(undefined);
    };

    useEffect(() => {
        getGiveaways().then((response: Array<Giveaway>) => {
            setGiveaways(response)
        })
    }, [])

    return (<>
        <div className="App-contents">
            <div className="Landing-content">

                <div className="Section-heading">
                    <h4>Giveaways</h4>
                </div>

                <div className="Section-heading">
                    <button onClick={() => setOpen(true)}>Add Giveaway</button>
                </div>

        <div className="Btcs-table">
            <ul className="Header">
                <li>Name</li>
                <li>Age</li>
                <li>Action</li>
            </ul>
            {
                giveaways.map(item => {
                    return <ul className="Entry">
                        <li>{item.name}</li>
                        <li>Giveaway</li>
                        <li className="Actions">
                            <button onClick={() => {
                                setEditing(item)
                                setOpen(true)
                            }}>Edit</button>
                            <button onClick={() => {
                                setOpenDelete(true)
                                setConfirmAction(() => () => {
                                    deleteGiveaway(item.id).then(r => {
                                        setGiveaways(r)
                                    })
                                })
                            }}>Delete</button>
                        </li>
                    </ul>
                })
            }
        </div>

        <GiveawayModal
            open={open}
            key={editing ? editing.id : 'no-id-giveaway'}
            onClose={handleClose}
            editing={editing}
            setGiveaways={setGiveaways}
        />

        <ConfirmActionModal
            open={openDelete}
            onClose={() => setOpenDelete(false)}
            action={confirmAction}
        />
            </div>
        </div>

    </>)
}