import '../Assets/CSS/Editor.scss'
import {useEffect, useRef, useState} from "react";
import { useParams } from 'react-router-dom';
import {axiosGet, axiosPost} from "../Utility/httpClient";

const getCode = async (uuid: string): Promise<string> => axiosGet(`/general/code?uuid=${uuid}`);
const compile = async (request: {code: string}): Promise<string> => axiosPost(`/general/compile-kotlin`, request);

export const KotlinCodeEditor: React.FC<any> = ({  }) => {

    const params = useParams();
    const editor = useRef<HTMLTextAreaElement>(null);
    const lineCounter = useRef<HTMLTextAreaElement>(null);
    const [code, setCode] = useState<string>('Loading...');
    const [running, setRunning] = useState<boolean>(false);
    const [output, setOutput] = useState<string>('Click on RUN button to see the output of the code.');
    const [codeKey, setCodeKey] = useState<string>('code-key-0');

    var lineCountCache = 0;

    function line_counter() {
        var lineCount = editor.current!!.value.split('\n').length;
        var outarr = new Array();
        if (lineCountCache != lineCount) {
            for (var x = 0; x < lineCount; x++) {
                outarr[x] = (x + 1) + '.';
            }
            lineCounter.current!!.value = outarr.join('\n');
        }
        lineCountCache = lineCount;
    }

    useEffect(() => {
        line_counter()
        getCode(params.id!!).then(r => {
            setCode(r)
            setCodeKey('code-key-1')
            line_counter()
        })
    }, [])

    return (<div className="KotlinCodeEditor">

        <div className="EditorHeader">
            <h2>Code Editor - Programming Language: Kotlin</h2>
            <div>
                <button onClick={() => {
                    if (running) return
                    setRunning(true)
                    compile({code: code}).then(r => {
                        setOutput(r)
                        setRunning(false)
                    })
                }}>{running ? 'Executing...' : 'Run Code'}</button>
            </div>
        </div>
        <div className="EditorView">
            <p className="Editor" key={codeKey}>
                <textarea id='lineCounter' ref={lineCounter} wrap='off' readOnly defaultValue={"1."} />
                <textarea id='codeEditor' ref={editor} onChange={e => {
                    line_counter()
                    setCode(e.target.value)
                }} defaultValue={code} wrap='off'/>
            </p>
            <div className="CodeOutput">
                <p>Output:</p>
                <p>{output}</p>
            </div>
        </div>

    </div>)
}