import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {makeStyles} from "@material-ui/core/styles";
import {numberWithCommas, toRuneScapeFormatFromExact} from "../Utility/utils";

export const useReferralTableStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch",
            background: 'transparent !important',
            borderRadius: '10px'
        }
    },
    table: {
        border: '1px solid transparent !important',
        borderRadius: '10px'
    },
    tableHeader: {
        background: 'transparent !important',
        border: '1px solid transparent !important',
    },
    tableRow: {
        "&:nth-of-type(odd)": {
            background: 'linear-gradient(90deg, #262931, transparent)'
        },
        "&:nth-of-type(even)": {
            background: 'linear-gradient(90deg, #262931, transparent)'
        },
        border: '1px solid transparent !important',
    },
    headerCell: {
        background: 'transparent !important',
        color: '#fff !important',
        borderBottom: '1px solid rgba(255, 255, 255, .0) !important',
        textTransform: 'uppercase',
        "&:first-child": {
            borderTopLeftRadius: '10px'
        },
    },
    tableCell: {
        color: '#cecece !important',
        borderBottom: '1px solid rgba(255, 255, 255, .1) !important',
        cursor: 'pointer'
    }
}))

interface Column {
    id: 'username' | 'since' | 'image' | 'wagered' | 'actions';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: any, row: any) => any;
}

const columns: readonly Column[] = [
    { id: 'username', label: 'Name', minWidth: 100, format: (v: any, row: any) => {
        console.log(row)
            return <div className="ChallengeCompletedName">
            <ul>
                <li> <div className="image-container"><img src={row.image} /></div></li>
                <li>{v}</li>
            </ul>
            </div>
        }},
    { id: 'wagered', label: 'Wagered', minWidth: 100, format: (v: any, row: any) => {
            return v ? numberWithCommas(v) : 'N/A '
        }},
    { id: 'since', label: 'Member Since', minWidth: 100, format: (v: any, row: any) => {
            return v ? v : 'N/A '
        }},
    { id: 'actions', label: 'Actions', minWidth: 100, format: (v: any, row: any) => {
            return v ? v : 'N/A '
        }},
];

export type CasinoBotsProps = {
    rows: Array<any>,
};

export const PlayerReferralsTable: React.FC<CasinoBotsProps> = ({
    rows,
}) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const classes = useReferralTableStyles();
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', background: 'transparent' }}>
            <TableContainer sx={{ maxHeight: 840 }}>
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                    <TableHead
                        className={classes.tableHeader}
                         style={{background: 'transparent !important'}}
                    >
                        <TableRow className={classes.tableRow} style={{background: 'transparent !important'}}>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    className={classes.headerCell}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    <span>{column.label}</span>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow className={classes.tableRow} hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {columns.map((column) => {
                                            const value = row[column.id];

                                            return (
                                                <TableCell className={classes.tableCell} key={column.id} align={column.align}>
                                                    <span>{// @ts-ignore
                                                        column.format(value, row)}</span>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                className={"HuntsPagination"}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}