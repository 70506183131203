import React from "react";
import Dialog from "@mui/material/Dialog";
import {ModalProps} from "../Routes/AdminChallenges";

export type ConfirmModalProps = {
} & ModalProps;

export default function AccountLinkingGuide(props: ConfirmModalProps) {
    const { onClose, open } = props;

    const dialogStyles = {
        backgroundColor: '#1b1d21',
        color: 'white',
        padding: '25px',
        boxShadow: 'none',
        width: '600px',
        height: '500px',
        overflow: 'hidden',
        borderRadius: '30px'
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
            <div className="Btcs-form Conditions-Modal">

                <p style={{marginTop: '0px', textAlign: 'left'}}>
                    Linking Guide: How to <span>Link Your RSN</span> to Discord<br/>
                    <br/>
                    Initiating the Link:<br/>
                    <span>Send a DM</span> with the word <span>Link</span> to <span>any</span> of our ranked bots <span>in-game</span> from the RSN you want to link.<br/>
                    The Friends Chat (FC) for these bots is always listed at the top of our Discord: <span>🟢︱FC: DD Hall</span><br/>
                    <br/>
                    Completing the Link:<br/>
                    The <span>bot will respond</span> with a specific <span>code</span> to type in Discord.<br/>
                    Go to the following channel and send the message in <span>💬︱spam</span><br/>
                    You will receive a DM in Discord confirming that your RSN has been successfully linked.<br/>
                    <br/>
                    Important Notes:<br/>
                    You can <span>link multiple RSNs</span> to your Discord account.<br/>
                    Once an RSN is linked to a Discord account, it <span>cannot be linked to another</span>.<br/>
                    For any issues with linking, please <span>create a support ticket</span>.<br/>
                </p>

            </div>

        </Dialog>
    );
}