import '../Assets/CSS/Editor.scss'
import {useEffect, useRef, useState} from "react";
import { useParams } from 'react-router-dom';
import {axiosGet, axiosPost} from "../Utility/httpClient";
import {Footer} from "../Components/Footer";
import {AccountProfile, WagerDTO, WonChallenge, WonLeaderboardRanking} from "../API/api";
import {makeStyles} from "@material-ui/core/styles";
import {ChallengeWinsTable} from "../Components/ChallengeWinsTable";
import {LeaderboardHistoricalRankingsTable} from "../Components/LeaderboardHistoricalRankingsTable";
import {GiveawayWinningsTable} from "../Components/GiveawayWinningsTable";
import {PlayerReferralsTable} from "../Components/PlayerReferralsTable";
import {PlayerPublicWagersTable} from "../Components/PlayerPublicWagersTable";
import {getLoadingBar} from "../Utility/loadingBar";
import AccountLinkingGuide from "../Components/AccountLinkingGuide";
import {
    Checkbox,
    Dialog,
    FormControl,
    FormControlLabel,
    InputLabel,
    ListItemText, MenuItem,
    OutlinedInput,
    Select
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    card: {
        minWidth: 100,
        maxHeight: 100,
        height: "100%",
        backgroundColor: 'transparent !important',
        margin: '15px',
        border: 'none',
        boxShadow: 'none',
    },
    cardContent: {
        backgroundSize: 'cover',
        backgroundColor: '#080808',
        height: '56px',
        border: '2px solid #2a2e38',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
        background: '#1b1d27 !important'
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
}));

const getProfile = async (): Promise<AccountProfile> => axiosGet(`/auth/profile`)
const getTopWins = async (username: string): Promise<Array<WagerDTO>> => axiosGet(`/profile/top-wins?username=${username}`);
const getChallengeWins = async (username: string): Promise<Array<WonChallenge>> => axiosGet(`/profile/challenges?username=${username}`);
const getLeaderboardPlacements = async (username: string): Promise<Array<WonLeaderboardRanking>> => axiosGet(`/profile/leaderboard-placements?username=${username}`);
const getWagers = async (username: string): Promise<Array<WagerDTO>> => axiosGet(`/profile/wagers?username=${username}`);

export const Profile: React.FC<any> = ({  }) => {

    const params = useParams();
    const [achievementsTab, setAchievementsTab] = useState(1);
    const [achievementsTabFilter, setAchievementsTabFilter] = useState(0);
    const [openLinkGuide, setOpenLinkGuide] = useState(false);
    const [view, setView] = useState<string>('Accounts');
    const [currentProfile, setCurrentProfile] = useState<string | undefined>(undefined);
    const [profile, setProfile] = useState<AccountProfile | undefined>(undefined);
    const [topWins, setTopWins] = useState<Array<WagerDTO> | undefined>(undefined);
    const [challengeWins, setChallengeWins] = useState<Array<WonChallenge> | undefined>(undefined);
    const [leaderboardPlacements, setLeaderboardPlacements] = useState<Array<WonLeaderboardRanking> | undefined>(undefined);
    const [wagers, setWagers] = useState<Array<WagerDTO> | undefined>(undefined);
    const categories = [ 'Accounts', 'Transactions' ]
    const classes = useStyles();

    const handleCategoryChange = (event: any) => {
        const {
            target: { value },
        } = event;
        setView(value)
    };

    const getCurrentView = () => {
        if (view == 'Accounts') {
            return <div className="Current-Tier-Container">
                   {getAccountsView()}
            </div>
        }
        return <div />
    }


    useEffect(() => {
        getProfile().then(r => {
                setProfile(r)
                setCurrentProfile(r.accounts[0])
            }).catch(r => window.location.href='https://dragonsden.gg/')
    }, [])


    useEffect(() => {
        if (currentProfile) {
            getTopWins(currentProfile.replaceAll(' ', '_')).then(r => setTopWins(r))
            getChallengeWins(currentProfile.replaceAll(' ', '_')).then(r => setChallengeWins(r))
//             getLeaderboardPlacements(currentProfile.replaceAll(' ', '_')).then(r => setLeaderboardPlacements(r))
            getWagers(currentProfile.replaceAll(' ', '_')).then(r => setWagers(r))
        }
    }, [currentProfile]);

    function getTable() {
        if (achievementsTab == 2) {
            if (!leaderboardPlacements) {
                return (<div className="Loader-bar-space">
                    {getLoadingBar(true, "Fetching Information...")}
                </div>)
            }
            return (<LeaderboardHistoricalRankingsTable rows={leaderboardPlacements} key={"leaderboard-wins-" + leaderboardPlacements.length} />)
        }
        if (achievementsTab == 3) {
                return (<div className="Loader-bar-space">
                    {getLoadingBar(true, "Fetching Information...")}
                </div>)
        }
        if (!challengeWins) {
                return (<div className="Loader-bar-space">
                    {getLoadingBar(true, "Fetching Information...")}
                </div>)
        }
        return (<ChallengeWinsTable rows={challengeWins} key={"challenges-won-" + challengeWins.length} />)
    }

    function getAccountsView() {

        if (profile!!.accounts.length == 0) {
            return (<div className="Current-Tier">
                <div className="Current-Tier-Info">
                    <div className="Current-Tier-Image">
                        <img src="https://dragonsden.gg/silver.png"/>
                    </div>
                    <div className="Current-Tier-Content">
                        <div className="Current-Tier-Title">
                            <h4>No Accounts</h4>
                        </div>
                        <div className="Current-Tier-Description">
                            <p>
                                You <b>do not</b> have any OSRS accounts <b>linked</b> to your Discord account. To learn more about linking accounts <b>click the Link button</b> below.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="Current-Tier-Claim">
                    <a><button onClick={() => setOpenLinkGuide(true)}>Link</button></a>
                </div>
                <br /><br /><br /><br /><br />

                <AccountLinkingGuide
                    open={openLinkGuide}
                    onClose={() => setOpenLinkGuide(false)}
                />
            </div>)
        }
        return <div className="OSRSAccount">

            <div className="OSRSAccountsBar">
                <select defaultValue={currentProfile} onChange={e => {
                    setTopWins(undefined)
                    setChallengeWins(undefined)
                    setLeaderboardPlacements(undefined)
                    setWagers(undefined)
                    setCurrentProfile(e.target.value)
                    }}>
                    {
                        profile!!.accounts.map(c => (<option value={c}>{c}</option>))
                    }
                </select>
             </div>

            { topWins ? (<div className="OSRSAccountSection">
                <div className="Heading">
                    <img src="/leaderboard-update-white.svg" />
                    <h3>Top Wins</h3>
                    <span>Your all-time biggest wins across all of our games.</span>
                 </div>

                 <div className="BiggestWins-List" key={"top-wins-" + topWins.length}>
                 { topWins.map(win => {
                        return (<div className="BiggestWin-Entry">
                                          <div className="EntryPic">

                                          </div>
                                          <div className="EntryValue">
                                             <ul>
                                                 <li>10M GP</li>
                                                 <li>Win</li>
                                             </ul>
                                             <ul>
                                                 <li>1</li>
                                             </ul>
                                             <ul>
                                                 <li>8</li>
                                                 <li>Highest Streak</li>
                                             </ul>
                                          </div>
                                      </div>
                                      )
                             })
                         }
                 </div>
             </div>) :
             (<div className="OSRSAccountSection">
                <div className="Heading">
                    <img src="/leaderboard-update-white.svg" />
                    <h3>Top Wins</h3>
                    <span>Your all-time biggest wins across all of our games.</span>
                 </div>
                <div className="Loader-bar-space">
                {getLoadingBar(true, "Fetching Information...")}
                </div>
             </div>)
             }
            <div className="OSRSAccountSection">
                <div className="Heading">
                    <img src="/achievements.svg" />
                    <h3>Achievements</h3>
                    <span>Leaderboards, Challenges, Giveaways and other Achievements.</span>
                 </div>
                 <div className="DDAchievements">
                     <div className="DDAchievements-Tabs">
                        <ul>
                            <li className={achievementsTab == 1 ? 'Active' : ''} onClick={() => setAchievementsTab(1)}>Challenges</li>
                            <li className={achievementsTab == 2 ? 'Active' : ''} onClick={() => setAchievementsTab(2)}>Leaderboards</li>
                            <li className={achievementsTab == 3 ? 'Active' : ''} onClick={() => setAchievementsTab(3)}>Giveaways</li>
                        </ul>
                     </div>
                     <div className="DDAchievements-TimeFilter">
                        <ul>
                            <li className={achievementsTabFilter == 0 ? 'Active' : ''} onClick={() => setAchievementsTabFilter(0)}>All</li>
                            <li className={achievementsTabFilter == 1 ? 'Active' : ''} onClick={() => setAchievementsTabFilter(1)}>YTD</li>
                            <li className={achievementsTabFilter == 2 ? 'Active' : ''} onClick={() => setAchievementsTabFilter(2)}>6-Months</li>
                            <li className={achievementsTabFilter == 3 ? 'Active' : ''} onClick={() => setAchievementsTabFilter(3)}>3-Months</li>
                            <li className={achievementsTabFilter == 4 ? 'Active' : ''} onClick={() => setAchievementsTabFilter(4)}>1-Month</li>
                            <li className={achievementsTabFilter == 5 ? 'Active' : ''} onClick={() => setAchievementsTabFilter(5)}>Week</li>
                            <li className={achievementsTabFilter == 6 ? 'Active' : ''} onClick={() => setAchievementsTabFilter(6)}>Today</li>
                        </ul>
                     </div>
                     <div className="DDAchievements-Table">
                        {
                         getTable()
                         }
                     </div>
                 </div>
             </div>

            <div className="OSRSAccountSection">
                <div className="Heading">
                    <img src="/refer.svg" />
                    <h3>Referrals</h3>
                    <span>Refer your friends to Dragon's Den and earn money as they place bets.</span>
                 </div>
                 <PlayerReferralsTable rows={[]} />
             </div>

            <div className="OSRSAccountSection">
                <div className="Heading">
                    <img src="/tickets.svg" />
                    <h3>Wagers</h3>
                    <span>A history of all of the bets you've placed with Dragon's Den.</span>
                 </div>

                 { wagers ?
                     (<PlayerPublicWagersTable rows={wagers} key={"wagers-" + wagers.length} />)
                     :
                     (<div className="Loader-bar-space">{ getLoadingBar(true, "Fetching Information...") }</div>)
                 }
             </div>

            <div>
                <div>
                </div>
                <div>
                </div>
                <div>
                </div>
            </div>

        </div>
    }


    if (!profile) {
        return (<>
            <div className="ProfileView">
            </div>
            </>)
    }
    return (<>
        <div className="App-contents">

            <div className="Landing-conten ProfileView">
            <div className="ViewSelection">

                    <div className="Form-input ModalSelect">
                        <FormControl sx={{ m: 1, width: 300 }}>
                            <InputLabel id="demo-multiple-checkbox-label" sx={{ color: 'white' }}>View</InputLabel>
                            <Select
                                key={"select-game"+view}
                                labelId="demo-multiple-checkbox-label"
                                className={classes.select}
                                inputProps={{
                                    classes: {
                                        icon: classes.icon,
                                        root: classes.root,
                                    },
                                }}
                                id="demo-multiple-checkbox"
                                value={view}
                                onChange={handleCategoryChange}
                                sx={{
                                    color: "white",
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#2a2e38',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#2a2e38',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#2a2e38',
                                    },
                                    '.MuiSvgIcon-root ': {
                                        fill: "white !important",
                                    }
                                }}

                                input={<OutlinedInput sx={{ color: 'white' }}
                                                      key={"input-game"+view}
                                                      label="View" />}
                                renderValue={(selected) => selected}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 48 * 4.5 + 8,
                                            width: 250,
                                            color: '#acacac',
                                            background: '#121418'
                                        },
                                    },
                                }}
                            >
                                {
                                    categories.map((variant) => (
                                        //@ts-ignore
                                        <MenuItem key={variant.id} value={variant}>
                                            <ListItemText primary={variant} />
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </div>
            </div>
            <div className="CurrentView">
                {getCurrentView()}
            </div>

                <br /><br /><br /><br /><br /><br />
                <Footer />
        </div>
        </div>

    </>)
}
