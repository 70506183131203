import React from "react";
import Dialog from "@mui/material/Dialog";
import {ModalProps} from "../Routes/AdminChallenges";

export type ConfirmModalProps = {
} & ModalProps;

export default function PrivacyPolicy(props: ConfirmModalProps) {
    const { onClose, open } = props;

    const dialogStyles = {
        backgroundColor: '#1b1d21',
        color: 'white',
        padding: '25px',
        boxShadow: 'none',
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        borderRadius: '30px'
    };

    const handleClose = () => {
        onClose();
    };

    let padded = []
    for (let i = 0; i < 38; i++) {
        padded.push(<h1>.</h1>)
    }
    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
            <div className="Btcs-form Confirm-Modal">
                {padded.map(d =>d)}

                <h1>Privacy Policy</h1>
                <p>DRAGONSDEN.GG ("we," "us," or "our") is dedicated to safeguarding the privacy and security of our website visitors and users ("you" or "your"). This Privacy Policy outlines how we collect, use, disclose, and protect personal information from website visitors and users. By using our website, you agree to the terms of this Privacy Policy.</p>

                <h2>Information We Collect</h2>
                <p>We may gather the following types of personal information from you:</p>
                <ul>
                    <li>Usage details, such as your IP address, browser type, and device type, when you visit our website</li>
                    <li>Any other information you choose to provide us</li>
                </ul>
                <p>Additionally, we collect general analytical data, which may include details like the pages you visit on our website, the time and date of your visit, and your browser type. This data helps us analyze and improve our website's performance. If you log in using your Twitch account, we collect your Twitch username, but not your Twitch password or any other personal information from your Twitch account.</p>

                <h2>How We Use Your Information</h2>
                <p>We may use your personal information for the following purposes:</p>
                <ul>
                    <li>To provide the products or services you request</li>
                    <li>To communicate with you about your orders, products, or services</li>
                    <li>To enhance our website, products, or services</li>
                    <li>To personalize your experience on our website</li>
                    <li>To send you marketing communications about products or services that may interest you</li>
                    <li>To comply with legal obligations or protect our legal rights</li>
                </ul>
                <p>We also use general analytical data to analyze and improve our website's performance.</p>

                <h2>How We Share Your Information</h2>
                <p>We may share your personal information with third-party service providers who help us operate our website or provide products or services to you. We may also share your information with government authorities or law enforcement officials to comply with legal requirements or protect our legal rights.</p>

                <h2>Your Choices</h2>
                <p>You can choose not to provide personal information, but this may limit your ability to use certain features of our website or receive certain products or services. You can contact us at <a href="mailto:admin@onlytk.xyz">admin@onlytk.xyz</a> to manage your preferences.</p>

                <h2>Security</h2>
                <p>We take reasonable measures to protect your personal information from unauthorized access, disclosure, or misuse. However, we cannot guarantee the complete security of your personal information, and you provide it at your own risk.</p>

                <h2>Changes to this Privacy Policy</h2>
                <p>We may update this Privacy Policy periodically by posting the revised policy on our website. The updated policy will take effect as soon as it is posted.</p>

                <h2>Contact Us</h2>
                <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:admin@onlytk.xyz">admin@onlytk.xyz</a>.</p>
                {/*<button className={"Alt"} onClick={() => handleClose()} style={{marginTop: '10px'}}>Back</button>*/}
                {/*<button onClick={() => { handleClose() } } style={{marginTop: '10px'}}>Proceed</button>*/}
                <button className={"Alt"} onClick={() => handleClose()} style={{marginTop: '10px'}}>Close</button>

            </div>

        </Dialog>
    );
}