import {Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis,} from "recharts";
import {toRuneScapeFormatFromExact} from "../Utility/utils";

export const Chart = ({ data }: { data: Array<any> }) => {
    return (
        <ResponsiveContainer width='100%' height='100%' key={"chart-" + data.length} aspect={3}>
            <AreaChart
                width={320} height={100}
                data={data}
                style={data.length > 1 && { marginLeft: "-20px" }}
            >
                <defs>
                    <linearGradient id="color" x1="0" y1="0">
                        {
                            data.length > 0 &&
                            data.map((ele, id) => {
                                    if (id) {
                                        if (ele.count > 0) {

                                            if (data[id - 1].count >= 0)
                                                return <stop offset={`${100 * (id + 1) / data.length}%`} stopColor="#1DB954" stopOpacity={0.5} />
                                            else {
                                                return (
                                                    <>
                                                        <stop offset={`${100 * (id) / data.length}%`} stopColor="#f34223" stopOpacity={0.5} />
                                                        <stop offset={`${100 * (id) / data.length}%`} stopColor="#1DB954" stopOpacity={0.5} />
                                                    </>
                                                )
                                            }

                                        } else {

                                            if (data[id - 1].count <= 0)
                                                return <stop offset={`${100 * (id + 1) / data.length}%`} stopColor="#f34223" stopOpacity={0.5} />
                                            else {
                                                return (
                                                    <>
                                                        <stop offset={`${100 * (id) / data.length}%`} stopColor="#1DB954" stopOpacity={0.5} />
                                                        <stop offset={`${100 * (id) / data.length}%`} stopColor="#f34223" stopOpacity={0.5} />
                                                    </>
                                                )
                                            }
                                        }
                                    }
                                }
                            )
                        }
                    </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="2 2" vertical={false} />
                <XAxis dataKey="time" tick={{ fill: 'white' }}  tickFormatter={tick => {
                    return new Date(tick).toString().substr(4, 6);
                }} />
                {
                    data.length > 1 &&
                    <YAxis tick={{ fill: '#B7BCD2FF', fontSize: 8 }} tickFormatter={tick => {
                        return toRuneScapeFormatFromExact(tick);
                    }} >
                        {/* <Label value={Math.min(...data.map(ele => ele.earnings)).toFixed(0)} position="insideBottomLeft" style={{ color: 'white' }} stroke="white" /> */}
                    </YAxis>
                }

                <Tooltip content={
                    //@ts-ignore
                    <CustomTooltip />
                } />
                <Area type="natural" dataKey="count" stroke="url(#color)" strokeWidth={2} fill="url(#color)" />
            </AreaChart>
        </ResponsiveContainer>
    )
}

// @ts-ignore
const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
        console.log(payload[0]?.payload)
        return (
            <div className="ChartTooltip">
                <ul className="Headings">
                    <li>
                        <p>Time</p>
                    </li>
                    <li>
                        <p>{new Date(label).toString().substr(16, 8)}</p>
                    </li>
                </ul>
                <ul className="Values">
                    <li>
                        <p>Net Gain</p>
                    </li>
                    <li>
                        <p>{toRuneScapeFormatFromExact(payload[0]?.payload?.count ?? 0)}</p>
                    </li>
                </ul>
            </div>
        );
    }

    return null;
};