import React, {useEffect} from "react";
import '../Assets/CSS/Admin.scss';
import {BotDTO, Player, PlayerDetails} from "../API/api";
import {axiosGet} from "../Utility/httpClient";
import {makeStyles} from "@material-ui/core/styles";
import {DiscordUsersTable} from "../Components/DiscordUsersTable";
import {Footer} from "../Components/Footer";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
        background: '#202429 !important'
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
    chip: {
        color: 'white !important',
        background: '#121418 !important',
        backgroundColor: '#121418 !important',
        border: '1px solid #2a2e38 !important',
        cursor: 'pointer !important'
    },
    card: {
        minWidth: 100,
        maxHeight: 100,
        height: "100%",
        backgroundColor: 'transparent !important',
        margin: '15px',
        border: 'none',
        boxShadow: 'none',
    },
    chipSelected: {
        backgroundSize: 'cover',
        height: '50px',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
        cursor: 'pointer !important',
        backgroundColor: '#080808',
        border: '3px solid #886CFF !important',
    },
    cardContent: {
        backgroundSize: 'cover',
        backgroundColor: '#080808',
        height: '56px',
        border: '2px solid #2a2e38',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
    },
}));

export const dialogStyles = {
    backgroundColor: '#1a1d23',
    color: 'white',
    padding: '25px',
    boxShadow: 'none',
};

const getSource = async (): Promise<Array<Player>> => axiosGet(`/admin/accounts`)

export type BotsProps = {
    userDetails: PlayerDetails | undefined
};

export const Accounts: React.FC<BotsProps> = ({ userDetails }) => {

    const [source, setSource] = React.useState<Array<Player>>([])
    const [user, setUser] = React.useState<Player|undefined>(undefined)
    const [activeIndex, setActiveIndex] = React.useState<number>(0)
    const [searchTerm, setSearchTerm] = React.useState<string | undefined>(undefined)
    const classes = useStyles();

    useEffect(() => {
        if (userDetails) {
            getSource().then(r => setSource(r))
        }
    }, [userDetails])

    const loadAndSetUser = (user: BotDTO) => {
        window.location.href = 'https://dragonsden.gg/admin/account/' + user.username
    }

    function getView(user: Player | undefined, source: Array<Player> | undefined, activeIndex: number, setActiveIndex: any, setUser: any): JSX.Element {

        return (<>

            <div className="Search-bar">
                <div className="Bar-content">
                    <svg id="Layer_1" width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path
                            d="M8.5 3a5.5 5.5 0 0 1 4.38 8.82l4.15 4.15a.75.75 0 0 1-.98 1.13l-.08-.07-4.15-4.15A5.5 5.5 0 1 1 8.5 3Zm0 1.5a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
                            fill="currentColor"/>
                    </svg>
                    <input type="text" defaultValue={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search for a Discord Account" />
                </div>
            </div>

            <div className="homeWidgets">
                <div className="widgetLg" style={{  minWidth: '100%', padding: '10px'}}>
                    <h3 className="widgetLgTitle" style={{  margin: '10px 0'}}>Discord Accounts</h3>
                    <DiscordUsersTable rows={(source ?? []).filter(r => searchTerm == undefined || r.username.toLowerCase().includes(searchTerm!!.toLowerCase()))} setUser={loadAndSetUser} />
                </div>
            </div>
            <br /><br /><br /><br />
        </>)
    }

    if (source) {

        return (<>
            <div className="App-contents">
                <div className="Landing-content">
                    <br/><br/>
                    {getView(user, source, activeIndex, setActiveIndex, setUser)}
                    <Footer/>
                </div>
            </div>
        </>)
    }
    return (<div>

        <div className="Section-heading">
            <h4>Loading...</h4>
        </div>

    </div>);
}