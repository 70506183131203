import React from "react";
import {PlayerDetails} from "../API/api";
import StarIcon from '@mui/icons-material/Star';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {makeStyles} from "@material-ui/core/styles";
import {Search} from "@material-ui/icons";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch",
            background: 'transparent !important',
            borderRadius: '10px'
        }
    },
    menuItem: {
        background: 'red !important'
    },
    menu: {
        background: 'red'
    }
}))

export type HeaderProps = {
    userDetails: PlayerDetails | undefined
    togglePanelVisible: any
};

export const Login = () => {
    const url =  'https://discord.com/oauth2/authorize?client_id=1268301726012936352&redirect_uri=https://dragonsden.gg/&response_type=code&scope=identify&prompt=none';
//     const url =  'https://discord.com/oauth2/authorize?client_id=1268301726012936352&redirect_uri=http://localhost:3000/&response_type=code&scope=identify';
    return { discUrl: url }
}


export const HorizontalHeader: React.FC<HeaderProps> = ({userDetails, togglePanelVisible}) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [open, setOpen] = React.useState<boolean>(false)

    const openMenu = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        console.log(event.currentTarget)
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const {discUrl} = Login()
    function getProfile(
        userDetails: PlayerDetails | undefined,
        handleClick: any,
        handleClose: any,
        openMenu: any,
        anchorEl: any,
        classes: any
    ) {

        var hideLogin = false
        const url = window.location.href;
        const codeMatches = url.match(/code=([^&]*)/);
        const errorMatches = url.match(/error=([^&]*)/);
        let code = undefined;
        let error = undefined;
        const hasToken = localStorage.getItem("bearer") != null;
        if (codeMatches) {
            code = codeMatches[0].substring(5);
        } else if (errorMatches) {
            error = errorMatches[0].substring(6);
        }
        if (code) {
            hideLogin = true
        }

        if (!userDetails) {
            return (<>
            <div className="App-userbar" style={{width: '80px', minWidth: '150px'}}>
                <ul>
                    <li>
                        <a href={hideLogin ? '#' : discUrl}>
                            <button>
                                <img width={35} src="/discord.svg" />
                                {hideLogin ? 'Authorizing...' : 'Sign In' }
                            </button>
                        </a>
                    </li>
                </ul>
            </div>
            <div className="App-userbar-mobile">
                <ul>
                    <div className="App-menu-button" onClick={() => togglePanelVisible()}></div>
                </ul>
            </div>
            </>)
        }
        return (<>
            <div className="App-userbar">
                <ul>
                    <li className="Profile-image" onClick={handleClick}><img className="ProfileImage" src={userDetails.image} /></li>
                    <li className="User-details" onClick={handleClick}>
                        <div className="Username">
                            <span>{userDetails.username}</span>
                            <ArrowDropDownIcon style={{marginTop: '20px', color: '#00c3ff'}} />
                        </div>
                        <div className="Balance">
                            <img src="/newcoin.svg" />
                            <span>{userDetails.balance.toLocaleString('en-US')} GP</span>
                        </div>
                    </li>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                        className={"Desktop-nav"}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem classes={classes.menuItem}><a href={'https://dragonsden.gg/profile'}>Profile</a></MenuItem>
                        {
                            userDetails.authority == 'USER' ? <></> : <MenuItem classes={classes.menuItem}><a href={'https://dragonsden.gg/admin'}>Admin CP</a></MenuItem>
                        }
                        <MenuItem classes={classes.menuItem} onClick={() => {
                            localStorage.removeItem("bearer");
                            localStorage.removeItem("refresh");
                            localStorage.removeItem("expiry");
                            localStorage.removeItem("twitch");
                            window.location.reload()
                        }}>Logout</MenuItem>
                    </Menu>

                </ul>
                </div>
                <div className="App-userbar-mobile">
                    <ul>
                        <div className="App-menu-button" onClick={() => togglePanelVisible()}></div>
                    </ul>
                </div>

                </>)
        }

    let active = 1
    if (window.location.href.includes('/leaderboard')) {
        active = 2
    }
    if (window.location.href.includes('/stats')) {
        active = 3
    }
    return (<header className="App-header">
        <div className="App-header-main">
            <div className="App-logo" onClick={() => window.location.href = 'https://dragonsden.gg/'}>
                <img width={55} height={80} src="/blue_dragonden_logo.png"/>
            </div>
            <div className="Navigation-Items">
                <ul>
                    {
                        navigationItems
                            .map((item, idx) => {
                            return (
                                <li className={idx == active ? "Active" : ""}><a href={`https://dragonsden.gg/${item.url}`}>
                                    {getIcon(item.title)}
                                    <p>{item.title}</p>
                                </a>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className="App-bar-right">
                {getProfile(userDetails, handleClick, handleClose, openMenu, anchorEl, classes)}
            </div>
        </div>
    </header>);
}


const navigationItems: Array<any> = [
    // {title: 'Search', width: 25, url: '', shortcut: true},
    // {title: 'Home', width: 25, url: ''},
    // {title: 'Leaderboards', width: 25, url: 'stats'},
]

function getIcon(title: string) {
    switch (title) {
        case 'Search': {
            return (
                <svg id="Layer_1" width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path
                        d="M8.5 3a5.5 5.5 0 0 1 4.38 8.82l4.15 4.15a.75.75 0 0 1-.98 1.13l-.08-.07-4.15-4.15A5.5 5.5 0 1 1 8.5 3Zm0 1.5a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
                        fill="currentColor"/>
                </svg>)
        }
        case 'Bots': {
            return (
                <svg id="Layer_1" width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 92">
                    <defs>
                    </defs>
                    <path
                        d="m78.204,8.091h-38.041c-.991,0-1.795.804-1.795,1.795v12.942c0,.908.736,1.644,1.644,1.644h17.376c.991,0,1.795.804,1.795,1.795v37.122c0,.825.669,1.494,1.494,1.494h17.526c.991,0,1.795-.804,1.795-1.795V9.886c0-.991-.804-1.795-1.795-1.795Z"/>
                    <path
                        d="m54.459,27.116H16.418c-.991,0-1.795.804-1.795,1.795v53.202c0,.991.804,1.795,1.795,1.795h38.041c.991,0,1.795-.804,1.795-1.795V28.911c0-.991-.804-1.795-1.795-1.795Zm-8.983,30.547l-9.176,12.233c-.431.574-1.292.574-1.723,0l-9.176-12.233c-.287-.383-.287-.91,0-1.293l9.176-12.233c.431-.574,1.292-.574,1.723,0l9.176,12.233c.287.383.287.91,0,1.293Z"/>
                </svg>
            )
        }
        case 'Players': {
            return (
                <svg id="Layer_1" width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 92">
                    <defs>
                    </defs>
                    <path
                        d="m78.204,8.091h-38.041c-.991,0-1.795.804-1.795,1.795v12.942c0,.908.736,1.644,1.644,1.644h17.376c.991,0,1.795.804,1.795,1.795v37.122c0,.825.669,1.494,1.494,1.494h17.526c.991,0,1.795-.804,1.795-1.795V9.886c0-.991-.804-1.795-1.795-1.795Z"/>
                    <path
                        d="m54.459,27.116H16.418c-.991,0-1.795.804-1.795,1.795v53.202c0,.991.804,1.795,1.795,1.795h38.041c.991,0,1.795-.804,1.795-1.795V28.911c0-.991-.804-1.795-1.795-1.795Zm-8.983,30.547l-9.176,12.233c-.431.574-1.292.574-1.723,0l-9.176-12.233c-.287-.383-.287-.91,0-1.293l9.176-12.233c.431-.574,1.292-.574,1.723,0l9.176,12.233c.287.383.287.91,0,1.293Z"/>
                </svg>
            )
        }
        case 'Stats': {
            return (<svg id="Layer_1" width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 92">
                <g id="Layer_7">
                    <g id="Group_19">
                        <rect id="Rectangle_107" className="cls-1" x="62.966" y="5.268" width="20.366"
                              height="81.464" rx="4.073" ry="4.073"/>
                        <rect id="Rectangle_108" className="cls-1" x="34.453" y="35.817" width="20.366"
                              height="50.915" rx="4.073" ry="4.073"/>
                        <rect id="Rectangle_109" className="cls-1" x="5.941" y="64.329" width="20.366"
                              height="22.403" rx="4.073" ry="4.073"/>
                    </g>
                </g>
            </svg>)
        }
    }

    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.7654 6.10154L8.00311 1L1.23458 6.10154C1.09465 6.23065 1.01051 6.40917 1 6.59925V14.3897C1.00049 14.5509 1.06476 14.7054 1.17878 14.8194C1.29279 14.9334 1.44729 14.9976 1.60853 14.9981H5.66667V11.7338C5.66667 11.115 5.9125 10.5216 6.35008 10.0841C6.78767 9.64655 7.38116 9.40075 8 9.40075C8.61884 9.40075 9.21233 9.64655 9.64992 10.0841C10.0875 10.5216 10.3333 11.115 10.3333 11.7338V15H14.3908C14.5521 14.9995 14.7067 14.9353 14.8208 14.8213C14.9349 14.7073 14.9993 14.5528 15 14.3915V6.59925C14.9892 6.40923 14.9051 6.23081 14.7654 6.10154Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}