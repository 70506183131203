import React, {useEffect} from "react";
import '../Assets/CSS/Admin.scss';
import {Casino, EarningsDay, PlayerDetails} from "../API/api";
import {Pie, PieChart, Sector} from 'recharts';
import {axiosGet} from "../Utility/httpClient";
import {makeStyles} from "@material-ui/core/styles";
import FeaturedInfo from "../Components/FeaturedInfo";
import {Chart} from "../Components/Chart";
import {Visibility} from "@material-ui/icons";
import {numberWithCommas, toRuneScapeFormatFromExact} from "../Utility/utils";
import {Footer} from "../Components/Footer";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
        background: '#202429 !important'
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
    chip: {
        color: 'white !important',
        background: '#121418 !important',
        backgroundColor: '#121418 !important',
        border: '1px solid #2a2e38 !important',
        cursor: 'pointer !important'
    },
    card: {
        minWidth: 100,
        maxHeight: 100,
        height: "100%",
        backgroundColor: 'transparent !important',
        margin: '15px',
        border: 'none',
        boxShadow: 'none',
    },
    chipSelected: {
        backgroundSize: 'cover',
        height: '50px',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
        cursor: 'pointer !important',
        backgroundColor: '#080808',
        border: '3px solid #886CFF !important',
    },
    cardContent: {
        backgroundSize: 'cover',
        backgroundColor: '#080808',
        height: '56px',
        border: '2px solid #2a2e38',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
    },
}));

export const dialogStyles = {
    backgroundColor: '#1a1d23',
    color: 'white',
    padding: '25px',
    boxShadow: 'none',
};

const getSource = async (): Promise<Casino> => axiosGet(`/admin/overview`)

export type StatsProps = {
    userDetails: PlayerDetails | undefined
};

export const DARK_COLOURS = ['#4C85B1', '#831C44', '#03C888', '#9EACDD'];
export const LIGHT_COLOURS = ['#5173bf', '#42bea5', '#ddae38', '#ba1033'];
export const LIGHT_COLOURS_INCOME_CHART = ["#448aff", "#ff9800"];
export const BAR_CHART_COLOURS_INCOME_CHART = ["#9575cd", "#448aff", "#ff9800"]

const renderActiveShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, name, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                Games
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{name}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`${(percent * 100).toFixed(2)}%`}
            </text>
        </g>
    );
};

const getEarnings = async (): Promise<Array<EarningsDay>> => axiosGet(`/admin/earnings`)
const getDiceEarnings = async (): Promise<Array<EarningsDay>> => axiosGet(`/admin/dice-earnings`)
const getPokerEarnings = async (): Promise<Array<EarningsDay>> => axiosGet(`/admin/flower-poker-earnings`)
const getCasketsEarnings = async (): Promise<Array<EarningsDay>> => axiosGet(`/admin/casket-earnings`)
const getHotColdEarnings = async (): Promise<Array<EarningsDay>> => axiosGet(`/admin/hot-cold-earnings`)

export const Stats: React.FC<StatsProps> = ({ userDetails }) => {

    const [source, setSource] = React.useState<Casino|undefined>(undefined)
    const [earnings, setEarnings] = React.useState<Array<EarningsDay>>([])
    const [diceEarnings, setDiceEarnings] = React.useState<Array<EarningsDay>>([])
    const [pokerEarnings, setPokerEarnings] = React.useState<Array<EarningsDay>>([])
    const [casketsEarnings, setCasketsEarnings] = React.useState<Array<EarningsDay>>([])
    const [hotColdEarnings, setHotColdEarnings] = React.useState<Array<EarningsDay>>([])
    const [activeIndex, setActiveIndex] = React.useState<number>(0)
    const classes = useStyles();

    useEffect(() => {
        if (userDetails) {
            getSource().then(r => setSource(r)).catch(e => window.location.href = "/admin")
            getEarnings().then(r => setEarnings(r))
            getDiceEarnings().then(r => setDiceEarnings(r))
            getPokerEarnings().then(r => setPokerEarnings(r))
            getCasketsEarnings().then(r => setCasketsEarnings(r))
            getHotColdEarnings().then(r => setHotColdEarnings(r))
        }
    }, [userDetails])

    function getView(source: Casino | undefined, activeIndex: number, setActiveIndex: any): JSX.Element {
        const sourceMetrics: Array<any> = [
            {key: 'Users', value: numberWithCommas(source?.users ?? 0)},
            {key: 'Bets', value: numberWithCommas(source?.bets.length ?? 0)},
            {key: 'Total Wagered', value: toRuneScapeFormatFromExact(source?.wagered ?? 0)},
            {key: 'Total Paid Out', value: toRuneScapeFormatFromExact(source?.payout ?? 0)},
        ]
        const sourceMetrics2: Array<any> = [
            {key: 'Total Rakeback', value: numberWithCommas(source?.totalRakeback ?? 0)},
            {key: 'Unclaimed Rakeback', value: toRuneScapeFormatFromExact(source?.unclaimedRakeback ?? 0)},
            {key: 'Profit / Loss', value: toRuneScapeFormatFromExact(source?.profit ?? 0)},
        ]
        return (<>
            <div className="Metrics-container">
                <FeaturedInfo metrics={sourceMetrics} />
            </div>
            <div className="Metrics-container">
                <FeaturedInfo metrics={sourceMetrics2} />
            </div>

            <div className="homeWidgets">
                <div className="widgetSm" style={{minWidth: '500px'}}>
                    <span className="widgetSmTitle">Top Games</span>
                    <div className='pie-chart'>
                        <PieChart width={600} height={400}>
                            <Pie
                                activeIndex={activeIndex}
                                activeShape={renderActiveShape}
                                data={source!.topGames}
                                cx="38%"
                                cy="50%"
                                innerRadius={85}
                                outerRadius={100}
                                fill={LIGHT_COLOURS[0]}
                                dataKey="value"
                                // onMouseEnter={this.onPieEnter}
                            />
                        </PieChart>
                    </div>
                </div>
                <div className="chart-container">
                    <br /><br />
                    <span className="widgetSmTitle">Overall Earnings</span>
                    <br /><br /><br /><br /><br />
                    <Chart data={earnings}/>
                </div>
            </div>


            <div className="homeWidgets">
                <div className="widgetSm">
                    <span className="widgetSmTitle">Top Gamblers</span>
                    <ul className="widgetSmList">
                        {

                            source!.topGamblers.map((gambler, idx) => {
                                return (<li className="widgetSmListItem">
                                    <img
                                        src="/newcoin.svg"
                                        alt=""
                                        className="widgetSmImg"
                                    />
                                    <div className="widgetSmUser">
                                        <span className="widgetSmUsername">{gambler.name}</span>
                                        <span className="widgetSmUserTitle">{toRuneScapeFormatFromExact(gambler.value)} GP</span>
                                    </div>
                                    <button className="widgetSmButton" onClick={() => {
                                    }}>
                                        <Visibility className="widgetSmIcon" />
                                        View
                                    </button>
                                </li>)
                            })
                        }
                    </ul>
                </div>

                <div className="widgetSm">
                    <span className="widgetSmTitle">Top Games</span>
                    <ul className="widgetSmList">
                        {
                            source!.topGames.map((game, idx) => {
                                return (<li className="widgetSmListItem">
                                    <img
                                        src="/newcoin.svg"
                                        alt=""
                                        className="widgetSmImg"
                                    />
                                    <div className="widgetSmUser">
                                        <span className="widgetSmUsername">{game.name} - {(game.value?.toFixed(2) ?? 0)}%</span>
                                        <span className="widgetSmUserTitle">{toRuneScapeFormatFromExact(game.avgBet ?? 0)} Avg. bet</span>
                                    </div>
                                    <button className="widgetSmButton" onClick={() => setActiveIndex(idx)}>
                                        <Visibility className="widgetSmIcon" />
                                        Display
                                    </button>
                                </li>)
                            })
                        }

                    </ul>
                </div>

                <div className="widgetSm">
                    <span className="widgetSmTitle">Top Bets</span>
                    <ul className="widgetSmList">
                            {
                                source!.topBets.map((game, idx) => {
                                    return (<li className="widgetSmListItem">
                                        <img
                                            src="/newcoin.svg"
                                            alt=""
                                            className="widgetSmImg"
                                        />
                                        <div className="widgetSmUser">
                                            <span className="widgetSmUsername">{game.name} [{game.game}]</span>
                                            <span className="widgetSmUserTitle">{toRuneScapeFormatFromExact(game.value?? 0)} GP</span>
                                        </div>

                                    </li>)
                                })
                            }
                        </ul>
                </div>
            </div>


            <div className="homeWidgets">
                <div className="chart-container">
                    <br /><br />
                    <span className="widgetSmTitle">Dice Earnings</span>
                    <br /><br /><br /><br /><br />
                    <Chart data={diceEarnings}/>
                </div>
                <div className="chart-container">
                    <br /><br />
                    <span className="widgetSmTitle">Flower Poker Earnings</span>
                    <br /><br /><br /><br /><br />
                    <Chart data={pokerEarnings}/>
                </div>
            </div>


            <div className="homeWidgets">
                <div className="chart-container">
                    <br /><br />
                    <span className="widgetSmTitle">Caskets Earnings</span>
                    <br /><br /><br /><br /><br />
                    <Chart data={casketsEarnings}/>
                </div>
                <div className="chart-container">
                    <br /><br />
                    <span className="widgetSmTitle">Hot / Cold Earnings</span>
                    <br /><br /><br /><br /><br />
                    <Chart data={hotColdEarnings}/>
                </div>
            </div>

            <br /><br /><br /><br />
        </>)
    }

    if (source) {

        return (<>
            <div className="App-contents">
                <div className="Landing-content">
                    <br/><br/><br/>
                    {getView(source, activeIndex, setActiveIndex)}
                    <Footer/>
                </div>
            </div>
        </>)
    }
    return (<div>

        <div className="Section-heading">
            <h4>Loading...</h4>
        </div>

    </div>);
}