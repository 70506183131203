import '../Assets/CSS/featuredInfo.scss'

import React from "react";

export type MetricsProps = {
  metrics: Array<any>
}
export const FeaturedInfo: React.FC<MetricsProps> = ({metrics}) => {
  return (
      <div className="featured">
        {
          metrics.map(metric => {
            return (<div className="featuredItem">
              <span className="featuredTitle">{metric.key}</span>
              <div className="featuredMoneyContainer">
                <span className="featuredMoney">{metric.value}</span>
                {/*<span className="featuredMoneyRate">*/}
                {/*   -11.4*/}
                {/*   <ArrowDownward className="featuredIcon negative"/>*/}
                {/*</span>*/}
              </div>
              {/*<span className="featuredSub">Compared to last month</span>*/}
            </div>)
          })
        }
      </div>
  );
}
export default FeaturedInfo;