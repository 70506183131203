import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {makeStyles} from "@material-ui/core/styles";
import {useFETableStyles} from "./ChallengeWinsTable";
import {numberWithCommas, toRuneScapeFormatFromExact} from "../Utility/utils";

interface Column {
    id: 'ranking' | 'image' | 'reward' | 'date';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: any, row: any) => any;
}

const columns: readonly Column[] = [
    { id: 'ranking', label: 'Ranking', minWidth: 100, format: (v: any, row: any) => {
        console.log(row)
            return <div className="ChallengeCompletedName">
            <ul>
                <li> <div className="image-container"><img src={row.image} /></div></li>
                <li>{v}</li>
            </ul>
            </div>
        }},
    { id: 'reward', label: 'Reward', minWidth: 100, format: (v: any, row: any) => {
            return v ? numberWithCommas(v) : 'N/A '
        }},
    { id: 'date', label: 'Date', minWidth: 100, format: (v: any, row: any) => {
            return v ? v : 'N/A '
        }},
];

export type CasinoBotsProps = {
    rows: Array<any>,
};

export const LeaderboardHistoricalRankingsTable: React.FC<CasinoBotsProps> = ({
    rows,
}) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const classes = useFETableStyles();
    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', background: 'transparent' }}>
            <TableContainer sx={{ maxHeight: 840 }}>
                <Table className={classes.table} stickyHeader aria-label="sticky table">
                    <TableHead
                        className={classes.tableHeader}
                    >
                        <TableRow className={classes.tableRow}>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    className={classes.headerCell}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    <span>{column.label}</span>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) => {
                                return (
                                    <TableRow className={classes.tableRow} hover role="checkbox" tabIndex={-1} key={row.id}>
                                        {columns.map((column) => {
                                            const value = row[column.id];

                                            return (
                                                <TableCell className={classes.tableCell} key={column.id} align={column.align}>
                                                    <span>{// @ts-ignore
                                                        column.format(value, row)}</span>
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                className={"HuntsPagination"}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
}