import React, {useEffect} from "react";
import '../Assets/CSS/Admin.scss';
import {Bot, BotDTO, EarningsDay, Player, PlayerDetails, WagerDTO, Wager} from "../API/api";
import {axiosGet, axiosPost} from "../Utility/httpClient";
import {makeStyles} from "@material-ui/core/styles";
import {BotWagersTable} from "../Components/BotWagersTable";
import {BotWagersTableMobile} from "../Components/BotWagersTableMobile";
import {ArrowBack} from "@material-ui/icons";
import {CasinoBotsTable} from "../Components/CasinoBotsTable";
import {CasinoBotsTableMobile} from "../Components/CasinoBotsTableMobile";
import {numberWithCommas, toRuneScapeFormatFromExact} from "../Utility/utils";
import {Chart} from "../Components/Chart";
import {Footer} from "../Components/Footer";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
        background: '#202429 !important'
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
    chip: {
        color: 'white !important',
        background: '#121418 !important',
        backgroundColor: '#121418 !important',
        border: '1px solid #2a2e38 !important',
        cursor: 'pointer !important'
    },
    card: {
        minWidth: 100,
        maxHeight: 100,
        height: "100%",
        backgroundColor: 'transparent !important',
        margin: '15px',
        border: 'none',
        boxShadow: 'none',
    },
    chipSelected: {
        backgroundSize: 'cover',
        height: '50px',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
        cursor: 'pointer !important',
        backgroundColor: '#080808',
        border: '3px solid #886CFF !important',
    },
    cardContent: {
        backgroundSize: 'cover',
        backgroundColor: '#080808',
        height: '56px',
        border: '2px solid #2a2e38',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
    },
}));

export const dialogStyles = {
    backgroundColor: '#1a1d23',
    color: 'white',
    padding: '25px',
    boxShadow: 'none',
};

const getBot = async (username: string): Promise<Bot> => axiosGet(`/admin/bot?username=${username}`)
const updateBotWealth = async (username: string, min: number, max: number): Promise<Bot> => axiosPost(`/admin/update-bot-wealth?username=${username}`, {min: min, max: max})
const updateBotChat = async (username: string, toggle: boolean): Promise<Bot> => axiosPost(`/admin/update-bot-chat?username=${username}`, {toggle: toggle})
const updateBotWager = async (username: string, min: number, max: number): Promise<Bot> => axiosPost(`/admin/update-bot-wager?username=${username}`, {min: min, max: max})
const getBotWagers = async (username: string): Promise<Array<Wager>> => axiosGet(`/admin/bot-wagers?username=${username}`)
const getBotEarnings = async (username: string): Promise<Array<EarningsDay>> => axiosGet(`/admin/bot-earnings?username=${username}`)
const getSource = async (): Promise<Array<BotDTO>> => axiosGet(`/admin/bots`)

export type BotsProps = {
    userDetails: PlayerDetails | undefined
};

export const Bots: React.FC<BotsProps> = ({ userDetails }) => {

    const [source, setSource] = React.useState<Array<BotDTO>>([])
    const [user, setUser] = React.useState<BotDTO|undefined>(undefined)
    const [botWagers, setBotWagers] = React.useState<Array<Wager>>([])
    const [botEarnings, setBotEarnings] = React.useState<Array<EarningsDay>>([])
    const [botDetails, setBotDetails] = React.useState<Bot|undefined>(undefined)
    const [activeIndex, setActiveIndex] = React.useState<number>(0)
    const [intFormValue, setIntFormValue] = React.useState<number>(0);
    const [intFormValue2, setIntFormValue2] = React.useState<number>(0);
    const [adding, setAdding] = React.useState<number>(0);
    const [addingWager, setAddingWager] = React.useState<number>(0);
    const [searchTerm, setSearchTerm] = React.useState<string | undefined>(undefined)

    useEffect(() => {
        if (userDetails) {
            getSource().then(r => setSource(r))
        }
    }, [userDetails])

    const loadAndSetUser = (user: BotDTO) => {
        setUser(user)
        getBot(user.username).then(r => setBotDetails(r))
        getBotWagers(user.username).then(r => setBotWagers(r))
        getBotEarnings(user.username).then(r => setBotEarnings(r))
    }

    function getView(user: Player | undefined, source: Array<Player> | undefined, activeIndex: number, setActiveIndex: any, setUser: any): JSX.Element {

        if (user) {
            if (botDetails) {
                const wagers: Array<WagerDTO> = []
                botWagers.map(w => {
                    wagers.push({
                        id: w.id,
                        player: w.player.username,
                        bot: w.bot?.displayName??'-',
                        clientSeed: w.clientSeed,
                        serverSeed: w.serverSeed,
                        nonce: w.nonce,
                        wagered: w.betGold + (w.betTokens * 1_000),
                        betTime: w.betTime,
                        game: w.game,
                        won: w.payout > 0,
                        payout: w.payout,
                    });
                })
                return (<>
                    <div className="Metrics-container">
                        <div className="Section-heading">
                            <h4 style={{textAlign: 'left'}}><ArrowBack width={80} onClick={() => { setUser(undefined) }} style={{marginRight: '10px'}} />{botDetails.displayName}</h4>
                        </div>
                    </div>

                    <div className="homeWidgets">
                        <div className="widgetSm">
                            <ul className="widgetSmList">
                                <ul>
                                    <li>Login</li><li>{botDetails.username}</li>
                                </ul>
                                <ul>
                                    <li>Username</li><li>{botDetails.displayName}</li>
                                </ul>
                                <ul>
                                    <li>Gold</li><li>{toRuneScapeFormatFromExact(botDetails.gold)}</li>
                                </ul>
                                <ul>
                                    <li>Platinum Tokens</li><li>{toRuneScapeFormatFromExact(botDetails.tokens)}</li>
                                </ul>
                                <ul>
                                    <li>Combat Level</li><li>{botDetails.combatLevel}</li>
                                </ul>
                                <ul>
                                    <li>Total Level</li><li>{numberWithCommas(botDetails.totalLevel)}</li>
                                </ul>
                                <ul>
                                    <li>Membership Left</li><li>{numberWithCommas(botDetails.membership)}</li>
                                </ul>
                                <ul>
                                    <li>Total Wagers</li><li>{numberWithCommas(botDetails?.totalWagers)}</li>
                                </ul>
                                <ul>
                                    <li>Total Wagered</li><li>{toRuneScapeFormatFromExact(botDetails?.totalWagered)}</li>
                                </ul>
                                <ul>
                                    <li>Net Profit</li><li>{toRuneScapeFormatFromExact(botDetails?.netProfit)}</li>
                                </ul>
                                <ul>
                                    <li>Public Chat Messages</li><li>{botDetails.usePublicChat ? 'Enabled' : 'Disabled'}</li>
                                </ul>
                                <ul>
                                    <li>Min Wealth</li><li>{toRuneScapeFormatFromExact(botDetails?.minWealth ?? 0)} GP</li>
                                </ul>
                                <ul>
                                    <li>Max Wealth</li><li>{toRuneScapeFormatFromExact(botDetails?.maxWealth ?? 0)} GP</li>
                                </ul>
                                <ul>
                                    <li>Min Wager</li><li>{toRuneScapeFormatFromExact(botDetails?.minWager ?? 0)} GP</li>
                                </ul>
                                <ul>
                                    <li>Max Wager</li><li>{toRuneScapeFormatFromExact(botDetails?.maxWager ?? 0)} GP</li>
                                </ul>
                                <ul>
                                    <li>Status</li><li>{botDetails.online ? 'ONLINE' : botDetails.lost ? 'LOST' : 'NORMAL'}</li>
                                </ul>
                                <ul>
                                    <li>
                                        <br />
                                    </li>

                                    {
                                        adding !== 0 ? <div>
                                            <br/>
                                            <span>Enter new {adding == -1 ? 'maximum' : 'minimum'}:</span>
                                            <div className="Btcs-form">
                                                <div className="Form-input-field">
                                                    <h6>GP</h6>
                                                    <div className="Form-input">
                                                        <div className="Form-icon">
                                                            <img src="/form/gold.webp"/>
                                                        </div>
                                                        <input type="number" placeholder="Points" defaultValue={intFormValue}
                                                               onChange={(e) => {
                                                                   setIntFormValue(Number(e.target.value))
                                                               }}/>
                                                        <h5 onClick={() => {
                                                            if (intFormValue > 0) {
                                                                const increase = adding === -1
                                                                setAdding(3)
                                                                    updateBotWealth(botDetails.username, increase ? botDetails.minWealth : intFormValue, increase ? intFormValue : botDetails.maxWealth).then(r => {
                                                                        setBotDetails(r)
                                                                        setAdding(0)
                                                                    })
                                                            }
                                                        }}>Update</h5>
                                                    </div>
                                                </div>
                                            </div>

                                        </div> : <ul>
                                            <li>
                                                <br />
                                                <button onClick={() => {
                                                    setAdding(1)
                                                    setIntFormValue(botDetails?.minWealth)
                                                }}>Update Min Wealth</button>
                                            </li>
                                            <li>
                                                <br />
                                                <button onClick={() => {
                                                    setAdding(-1)
                                                    setIntFormValue(botDetails?.maxWealth)
                                                }}>Update Max Wealth</button>
                                            </li>
                                        </ul>
                                    }
                                </ul>
                                <ul>
                                    <li>
                                        <br />
                                    </li>

                                    {
                                        addingWager !== 0 ? <div>
                                            <br/>
                                            <span>Enter new {addingWager == -1 ? 'maximum' : 'minimum'}:</span>
                                            <div className="Btcs-form">
                                                <div className="Form-input-field">
                                                    <h6>GP</h6>
                                                    <div className="Form-input">
                                                        <div className="Form-icon">
                                                            <img src="/form/gold.webp"/>
                                                        </div>
                                                        <input type="number" placeholder="Points" defaultValue={intFormValue2}
                                                               onChange={(e) => {
                                                                   setIntFormValue2(Number(e.target.value))
                                                               }}/>
                                                        <h5 onClick={() => {
                                                            if (intFormValue2 > 0) {
                                                                const increase = addingWager === -1
                                                                setAddingWager(3)
                                                                    updateBotWager(botDetails.username, increase ? botDetails.minWager : intFormValue2, increase ? intFormValue2 : botDetails.maxWager).then(r => {
                                                                        setBotDetails(r)
                                                                        setAddingWager(0)
                                                                    })
                                                            }
                                                        }}>Update</h5>
                                                    </div>
                                                </div>
                                            </div>

                                        </div> : <ul>
                                            <li>
                                                <br />
                                                <button onClick={() => {
                                                    setAddingWager(1)
                                                    setIntFormValue2(botDetails?.minWealth)
                                                }}>Update Min Wager</button>
                                            </li>
                                            <li>
                                                <br />
                                                <button onClick={() => {
                                                    setAddingWager(-1)
                                                    setIntFormValue2(botDetails?.maxWealth)
                                                }}>Update Max Wager</button>
                                            </li>
                                        </ul>
                                    }
                                </ul>
                                <ul>
                                    <li>
                                        <br/>
                                    </li>
                                    <li>
                                        <br/>
                                        <button onClick={() =>
                                            updateBotChat(botDetails.username, !botDetails.usePublicChat).then(r=>setBotDetails(r))
                                            }>Toggle Public Chat</button>
                                    </li>
                                </ul>
                            </ul>
                        </div>
                        <div className="widgetSm" style={{minWidth: '800px'}}>
                            <span className="widgetSmTitle">Earnings</span>
                            <ul className="widgetSmList">
                                <Chart data={botEarnings ?? []}/>
                            </ul>
                        </div>

                    </div>

                    <div className="homeWidgets">
                        <div className="widgetSm" style={{minWidth: '100%'}}>
                            <span className="widgetSmTitle">Wager History</span>
                            <ul className="widgetSmList">

                            <div className="Navigation-Items-Visible">
                                <BotWagersTable rows={wagers} user={user} />
                            </div>
                            <div className="Navigation-Items-Mobile">
                                <BotWagersTableMobile rows={wagers} user={user} />
                            </div>
                            </ul>
                        </div>

                    </div>
                </>)
            }
            return (<div>

                <div className="Section-heading">
                    <h4>Loading...</h4>
                </div>

            </div>);
        }
        return (<>

                <div className="Search-bar">
                    <div className="Bar-content">
                        <svg id="Layer_1" width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path
                                d="M8.5 3a5.5 5.5 0 0 1 4.38 8.82l4.15 4.15a.75.75 0 0 1-.98 1.13l-.08-.07-4.15-4.15A5.5 5.5 0 1 1 8.5 3Zm0 1.5a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
                                fill="currentColor"/>
                        </svg>
                        <input type="text" defaultValue={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search for a bot..." />
                    </div>
                </div>

            <div className="homeWidgets">
                <div className="widgetLg" style={{  minWidth: '100%', padding: '10px'}}>
                    <h3 className="widgetLgTitle" style={{  margin: '10px 0'}}>Bots</h3>
                        <div className="Navigation-Items-Visible">
                            <CasinoBotsTable rows={
                                (source ?? []).filter((r: any) => {
                                return searchTerm == undefined || r.displayName.toLowerCase().includes(searchTerm!!.toLowerCase());
                            })
                        } setUser={loadAndSetUser} />
                        </div>
                        <div className="Navigation-Items-Mobile">
                            <CasinoBotsTableMobile rows={
                                (source ?? []).filter((r: any) => {
                                return searchTerm == undefined || r.displayName.toLowerCase().includes(searchTerm!!.toLowerCase());
                                })
                            } setUser={loadAndSetUser} />
                        </div>
                </div>
            </div>
            <br /><br /><br /><br />
        </>)
    }

    if (source) {

        return (<>
            <div className="App-contents">
                <div className="Landing-content">
                    <br/><br/>
                    {getView(user, source, activeIndex, setActiveIndex, setUser)}
                    <Footer/>
                </div>
            </div>
        </>)
    }
    return (<div>

        <div className="Section-heading">
            <h4>Loading...</h4>
        </div>

    </div>);
}