import React from "react";
import {PlayerDetails} from "../API/api";
import StarIcon from '@mui/icons-material/Star';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {makeStyles} from "@material-ui/core/styles";
import {Search} from "@material-ui/icons";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export type InfoProps = {
};

export const HallInfo: React.FC<InfoProps> = () => {
    return (<div className="Hall-info-header">
            <ul>
                <li>Status <span>🟢 Online</span></li>
                <li>World <span>🌎 320</span></li>
                <li>Hall <span>🤝 Dragon's Hall</span></li>
                <li>Clan Channel <span>💬 DD Hall</span></li>
            </ul>
       </div>);
}
