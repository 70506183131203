import React from "react";
import Dialog from "@mui/material/Dialog";
import {ModalProps} from "../Routes/AdminChallenges";

export type ConfirmModalProps = {
} & ModalProps;

export default function TermsOfService(props: ConfirmModalProps) {
    const { onClose, open } = props;

    const dialogStyles = {
        backgroundColor: '#1b1d21',
        color: 'white',
        padding: '25px',
        boxShadow: 'none',
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        borderRadius: '30px'
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
            <div className="Btcs-form Conditions-Modal">

                <h1>Dragons Den Clan Hall and Discord Terms of Service</h1>
                <br />
                <h2>Introduction</h2>
                <br />
                <p>Welcome to Dragons Den Clan Hall and Discord in Old School RuneScape. By using our services, you agree to comply with the following terms and conditions. We aim to provide a fair and enjoyable experience for all members. Please read these terms carefully.</p>
                <br /><br />

                <h2>Fair Gaming</h2>
                <br /><br />
                <h3>Provably Fair Games</h3>
                <p>All games that do not use in-game mechanics are provably fair. You can verify the fairness of these games here: <a href="[link here]">[link here]</a>. We are committed to maintaining transparency and integrity in all our activities.</p>

                <br />
                <h3>In-Game Mechanics</h3>
                <p>Any game that uses in-game mechanics can be viewed and shown through livestream within the Discord at all times. This ensures that all outcomes are visible and verifiable by our community.</p>
                <br /><br />

                <h2>Responsibility</h2>

                <br /><br />

                <h3>User Responsibility</h3>
                <p>You are responsible for your account's actions while using our services. Ensure that you follow all rules and guidelines to maintain a safe and enjoyable environment for everyone.</p>

                <br />
                <h3>Account Actions</h3>
                <p>We are not responsible for any actions taken on your RuneScape account. Always exercise caution and protect your account credentials.</p>

                <br />
                <h3>Scams and Impersonators</h3>
                <p>If you are scammed by impersonators, we are not responsible for refunding. Always verify the identity of individuals you interact with.</p>

                <br />
                <h3>Rank Verification</h3>
                <p>Our hosts and staff will always be ranked within the clan chat. Be sure to always check before trading or talking about sensitive information.</p>
                <br /><br />

                <h2>Trading and Bets</h2>
                <br /><br />

                <h3>No Direct Trading</h3>
                <p>Never trade your gold over to a staff member. We will never ask you to trade us directly. All bets should be traded directly to the ranked bots within the game.</p>

                <br />
                <h3>Payment Assurance</h3>
                <p>In the event you are not paid out by the bot, rest assured all bets not paid out right away will be paid within 24 hours in case of an issue retrieving funds. Our team is dedicated to resolving any issues promptly.</p>

                <br />
                <h3>Bot Errors</h3>
                <p>In the event the bot breaks or misplays a game, the player's bet will be refunded and traded over by a staff member. If a staff member is not available, open a support ticket within Discord, and you should be refunded within 24 hours.</p>
                <br /><br />

                <h2>Real World Trade (RWT)</h2>
                <br /><br />

                <h3>No RWT Services</h3>
                <br />
                <p>We will never buy or sell your gold. What you do with your winnings from the bot is your responsibility. What happens outside of our services is not our responsibility. Engaging in RWT can result in consequences from Jagex, and we encourage players to abide by RuneScape's terms of service.</p>
                <br /><br />

                <h2>Rights and Regulations</h2>
                <br /><br />

                <h3>Ban Rights</h3>
                <p>We reserve the right to ban you from using our services if we see fit. This is to ensure the safety and integrity of our community.</p>

                <br />
                <h3>Rank and Wager Management</h3>
                <p>We reserve the right to remove/reset rank, wager, and any stats if we see fit. This is to maintain fairness and balance in our system.</p>

                <br />
                <h3>Giveaway Winnings and Credits</h3>
                <p>We reserve the right to remove any giveaway winnings or credits at any time if we see fit. All giveaways or promotion</p>



                <button className={"Alt"} onClick={() => handleClose()} style={{marginTop: '10px'}}>Close</button>
                {/*<button onClick={() => { handleClose() } } style={{marginTop: '10px'}}>Proceed</button>*/}

            </div>

        </Dialog>
    );
}