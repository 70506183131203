import React from "react";
import Dialog from "@mui/material/Dialog";
import {ModalProps} from "../Routes/AdminChallenges";

export type ConfirmModalProps = {
} & ModalProps;

export default function FAQ(props: ConfirmModalProps) {
    const { onClose, open } = props;

    const dialogStyles = {
        backgroundColor: '#1b1d21',
        color: 'white',
        padding: '25px',
        boxShadow: 'none',
        width: '300px',
        overflow: 'hidden',
        borderRadius: '30px'
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} PaperProps={{ style: dialogStyles }}>
            <div className="Btcs-form Confirm-Modal">

                <p style={{marginTop: '50px'}}>
                    The <span>FAQ</span> page is coming soon.
                </p>

            </div>

        </Dialog>
    );
}