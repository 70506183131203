import React from "react";
import {PlayerDetails} from "../API/api";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {makeStyles} from "@material-ui/core/styles";
import {Cake, EmojiEvents, People} from "@material-ui/icons";
import {MilitaryTech, PrecisionManufacturing} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch",
            background: 'transparent !important',
            borderRadius: '10px'
        }
    },
    menuItem: {
        background: 'red !important'
    },
    menu: {
        // background: 'red'
    }
}))

export type HeaderProps = {
    userDetails: PlayerDetails | undefined
    togglePanelVisible: any
};

export const Login = () => {
    const client_id = process.env.REACT_APP_TWITCH_CLIENT_ID;
    const redirect_uri = process.env.REACT_APP_TWITCH_REDIRECT_URI;
    const response_type = 'code';
    const scopes = [
        'chat',
        'edit+chat',
        'read+openid',
    ];
    const scope = scopes.reduce((s1, s2) => s1 + ':' + s2);
    const params = `client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}&scope=${scope}`;
    const url = `https://id.twitch.tv/oauth2/authorize?${params}`;
    return { url: url }
}

export const AdminHeader: React.FC<HeaderProps> = ({userDetails, togglePanelVisible}) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [toggledMobile, setToggledMobile] = React.useState<boolean>(false)
    const [open, setOpen] = React.useState<boolean>(false)

    const openMenu = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        console.log(event.currentTarget)
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const {url} = Login()
 
    function getProfile(
        userDetails: PlayerDetails | undefined,
        handleClick: any,
        handleClose: any,
        openMenu: any,
        anchorEl: any,
        classes: any
    ) {

        if (!userDetails) {
            return (<>
            <div className="App-userbar" style={{width: '80px', minWidth: '130px'}}>
                <ul>
                    <li>
                        <a href={url}>
                        {/*<button>Login</button>*/}
                    </a>
                    </li>
                </ul>
            </div>
            <div className="App-userbar-mobile">
                <ul>
                    <div className="App-menu-button" onClick={() => togglePanelVisible()}></div>
                </ul>
            </div>
            </>)
        }
        return (<>
            <div className="App-userbar">
                <ul>
                    <li className="Profile-image" onClick={handleClick}><img className="ProfileImage" src={"/btcs-coin2.png"} /></li>
                    <li className="User-details" onClick={handleClick}>
                        <span>{userDetails.username}</span>
                    </li>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                        className={"Desktop-nav"}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem classes={classes.menuItem}><a href={'https://dragonsden.gg/profile'}>Profile</a></MenuItem>
                        <MenuItem classes={classes.menuItem} onClick={() => {
                            localStorage.removeItem("bearer");
                            localStorage.removeItem("refresh");
                            localStorage.removeItem("expiry");
                            localStorage.removeItem("twitch");
                            window.location.reload()
                        }}>Logout!</MenuItem>
                    </Menu>

                </ul>
                </div>
                <div className="App-userbar-mobile">
                    <ul>
                        <div className="App-menu-button" onClick={() => togglePanelVisible()}></div>
                    </ul>
                </div>

                </>)
        }

    let active = 0
    if (window.location.href.includes('/stats')) {
        active = 1
    }
    if (window.location.href.includes('/bots')) {
        active = 2
    }
    if (window.location.href.includes('/accounts')) {
        active = 3
    }
    if (window.location.href.includes('/players')) {
        active = 4
    }
    if (window.location.href.includes('/challenges')) {
        active = 5
    }
    if (window.location.href.includes('/leaderboards')) {
        active = 6
    }
    if (window.location.href.includes('/giveaways')) {
        active = 7
    }
    if (window.location.href.includes('/chat-bot')) {
        active = 8
    }
    return (<header className="Admin-header">
        <div className="App-header-main">
            <div className="App-logo" onClick={() => setToggledMobile(!toggledMobile)}>
                <img width={45} height={45} src="/gold.png"/>
                <span>Beta</span>
            </div>
            <div className="Navigation-Items">
                <ul>
                    {
                        userDetails?.authority == 'ADMINISTRATOR' ?
                            navigationItemsAdmin
                            .map((item, idx) => {
                            return (
                                <li className={idx == active ? "Active" : ""}><a href={`https://dragonsden.gg/${item.url}`}>
                                    {getIcon(item.title)}
                                    <p>{item.title}</p>
                                </a>
                                </li>
                            )
                        })
                    :
                        navigationItems
                            .map((item, idx) => {
                            return (
                                <li className={idx == active ? "Active" : ""}><a href={`https://dragonsden.gg/${item.url}`}>
                                    {getIcon(item.title)}
                                    <p>{item.title}</p>
                                </a>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            {/*<div className="App-bar-right">*/}
            {/*    {getProfile(userDetails, handleClick, handleClose, openMenu, anchorEl, classes)}*/}
            {/*</div>*/}
        </div>
    </header>);
}


const navigationItems = [
    {title: 'Dashboard', width: 25, url: 'admin'},
    {title: 'Challenges', width: 25, url: 'admin/challenges'},
    {title: 'Prizes', width: 25, url: 'admin/prizes'},
    {title: 'Giveaways', width: 25, url: 'admin/giveaways'},
]

const navigationItemsAdmin = [
    {title: 'Dashboard', width: 25, url: 'admin'},
    {title: 'Stats', width: 25, url: 'admin/stats'},
    {title: 'Bots', width: 25, url: 'admin/bots'},
    {title: 'Accounts', width: 25, url: 'admin/accounts'},
    {title: 'Players', width: 25, url: 'admin/players'},
    {title: 'Challenges', width: 25, url: 'admin/challenges'},
    {title: 'Leaderboards', width: 25, url: 'admin/leaderboards'},
    {title: 'Giveaways', width: 25, url: 'admin/giveaways'},
    {title: 'Chat Bots', width: 25, url: 'admin/chat-bots'},
]

function getIcon(title: string) {
    switch (title) {
        case 'Search': {
            return (
                <svg id="Layer_1" width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path
                        d="M8.5 3a5.5 5.5 0 0 1 4.38 8.82l4.15 4.15a.75.75 0 0 1-.98 1.13l-.08-.07-4.15-4.15A5.5 5.5 0 1 1 8.5 3Zm0 1.5a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
                        fill="currentColor"/>
                </svg>)
        }
        case 'Challenges': {
            return (
                <EmojiEvents width={20}/>
            )
        }
        case 'Giveaways': {
            return (
               <Cake width={20} />
            )
        }
        case 'Chat Bots':
        case 'Bots': {
            return (
               <PrecisionManufacturing width={20} />
            )
        }
        case 'Accounts':
        case 'Players': {
            return (
                <People width={20} />
            )
        }
        case 'Leaderboards': {
            return (
                <MilitaryTech width={20} />
            )
        }
        case 'Stats': {
            return (<svg id="Layer_1" width={20} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 92 92">
                <g id="Layer_7">
                    <g id="Group_19">
                        <rect id="Rectangle_107" className="cls-1" x="62.966" y="5.268" width="20.366"
                              height="81.464" rx="4.073" ry="4.073"/>
                        <rect id="Rectangle_108" className="cls-1" x="34.453" y="35.817" width="20.366"
                              height="50.915" rx="4.073" ry="4.073"/>
                        <rect id="Rectangle_109" className="cls-1" x="5.941" y="64.329" width="20.366"
                              height="22.403" rx="4.073" ry="4.073"/>
                    </g>
                </g>
            </svg>)
        }
    }

    return (<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.7654 6.10154L8.00311 1L1.23458 6.10154C1.09465 6.23065 1.01051 6.40917 1 6.59925V14.3897C1.00049 14.5509 1.06476 14.7054 1.17878 14.8194C1.29279 14.9334 1.44729 14.9976 1.60853 14.9981H5.66667V11.7338C5.66667 11.115 5.9125 10.5216 6.35008 10.0841C6.78767 9.64655 7.38116 9.40075 8 9.40075C8.61884 9.40075 9.21233 9.64655 9.64992 10.0841C10.0875 10.5216 10.3333 11.115 10.3333 11.7338V15H14.3908C14.5521 14.9995 14.7067 14.9353 14.8208 14.8213C14.9349 14.7073 14.9993 14.5528 15 14.3915V6.59925C14.9892 6.40923 14.9051 6.23081 14.7654 6.10154Z"
                stroke="white"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}