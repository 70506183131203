import {Footer} from "../Components/Footer";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {PendingWager, PlatformOverview, PlayerDetails} from "../API/api";
import FeaturedInfo from "../Components/FeaturedInfo";
import {fiatWithCommas, numberWithCommas, toRuneScapeFormatFromExact} from "../Utility/utils";
import {PendingPayoutsTable} from "../Components/PendingPayoutsTable";
import {PendingPayoutsTableMobile} from "../Components/PendingPayoutsTableMobile";
import {axiosGet, axiosPost} from "../Utility/httpClient";
import ConfirmActionModal from "../Components/ConfirmActionModal";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    card: {
        minWidth: 100,
        maxHeight: 100,
        height: "100%",
        backgroundColor: 'transparent !important',
        margin: '15px',
        border: 'none',
        boxShadow: 'none',
    },
    cardContent: {
        backgroundSize: 'cover',
        backgroundColor: '#080808',
        height: '56px',
        border: '2px solid #2a2e38',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
        background: '#1b1d27 !important'
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
}));

export type LandingProps = {
    userDetails: PlayerDetails | undefined
};

const platformStatistics = async (): Promise<PlatformOverview> => axiosGet(`/moderate/stats`)
const pendingTransactions = async (): Promise<Array<PendingWager>> => axiosGet(`/moderate/pending-transactions`)
const completeTransaction = async (uuid: string): Promise<Array<PendingWager>> => axiosPost(`/moderate/complete-transaction`, {uuid:uuid})

export const AdminDashboard: React.FC<LandingProps> = ({ userDetails }) => {
    const classes = useStyles();
    const [stats, setStats] = useState<PlatformOverview | undefined>(undefined);
    const [transactions, setTransactions] = useState<Array<PendingWager>>([]);

    useEffect(() => {
        if (userDetails) {
            platformStatistics().then(response => {
                setStats(response)
            })
            pendingTransactions().then(response => {
                setTransactions(response)
            })
        }

    }, [userDetails])

    const sourceMetrics: Array<any> = [
        {key: 'Bets Today', value: numberWithCommas( stats?.betsToday ?? 0)},
        {key: 'New Sign-ups Today', value: numberWithCommas(stats?.signupsToday ?? 0)},
        {key: 'New Sign-ups This Month', value: numberWithCommas(stats?.signupsMonth ?? 0)},
        {key: 'Net Profit', value: toRuneScapeFormatFromExact(stats?.netProfit ?? 0)},
        {key: 'Bots Online', value: numberWithCommas( stats?.botsOnline ?? 0)},
    ]
    const [confirmAction, setConfirmAction] = useState(() => () => console.log("default ooops"));
    const [openConfirm, setOpenConfirm] = React.useState(false);

    const cancelAction = (param: any) => {
        setConfirmAction(() => () => {
            completeTransaction(param).then(response => {
                setTransactions(response)
            })
        })
        setOpenConfirm(true)
    }

    return (<>
        <div className="App-contents">
            <div className="Landing-content">
            <br /><br /><br />

                <div className="Metrics-container">
                    <FeaturedInfo metrics={sourceMetrics} />
                </div>

                <div className="homeWidgets">
                    <div className="widgetLg" style={{  minWidth: '100%', padding: '10px'}}>
                        <h3 className="widgetLgTitle" style={{  margin: '10px 0'}}>Pending Payments</h3>
                        <div className="Navigation-Items-Visible"><PendingPayoutsTable rows={transactions} actions={[cancelAction]} /></div>
                        <div className="Navigation-Items-Mobile"><PendingPayoutsTableMobile rows={transactions} actions={[cancelAction]} /></div>
                    </div>
                </div>

                <ConfirmActionModal
                    open={openConfirm}
                    onClose={() => setOpenConfirm(false)}
                    action={confirmAction}
                />
                <Footer/>
            </div>
        </div>
    </>)
}