import {Footer} from "../Components/Footer";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Tabs from "../Components/Tabs/Tabs";
import {
    BlacklistRequest,
    Challenge,
    CreateChallengeRequest,
    EditChallengeRequest,
    Player,
    PlayerDetails,
    StaticList
} from "../API/api";
import {axiosGet, axiosPost, axiosPut} from "../Utility/httpClient";
import {dialogStyles} from "./Players";
import {Checkbox, Dialog, FormControlLabel} from "@mui/material";
import moment from "moment";
import {BlacklistModalProps, EditBlacklistModal} from "./AdminChallenges";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    card: {
        minWidth: 100,
        maxHeight: 100,
        height: "100%",
        backgroundColor: 'transparent !important',
        margin: '15px',
        border: 'none',
        boxShadow: 'none',
    },
    cardContent: {
        backgroundSize: 'cover',
        backgroundColor: '#080808',
        height: '56px',
        border: '2px solid #2a2e38',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
        background: '#1b1d27 !important'
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
}));

export type LandingProps = {
    userDetails: PlayerDetails | undefined
};

export const AdminLeaderboards: React.FC<LandingProps> = ({ userDetails }) => {
    const classes = useStyles();

    const getLeaderboardPrizes = async (): Promise<StaticList> => axiosGet(`/moderate/leaderboard-prizes`)
    const getLeaderboardBlacklist = async (): Promise<StaticList> => axiosGet(`/moderate/blacklist`)

    const [prizes, setPrizes] = useState<StaticList>({strings:''});
    const [openPrizes, setOpenPrizes] = React.useState(false);
    const [blacklist, setBlacklist] = useState<StaticList>({strings:''});
    const [openBlacklist, setOpenBlacklist] = React.useState(false);

    useEffect(() => {
        getLeaderboardPrizes().then((response: StaticList) => {
            setPrizes(response)
        })
        getLeaderboardBlacklist().then((response: StaticList) => {
            setBlacklist(response)
        })
    }, [])

    const handleClose = () => {
        setOpenPrizes(false);
        setOpenBlacklist(false);
    };

    const prizeNames = prizes?.strings?.toString()?.split('\n') ?? ['']
    const blacklistNames = blacklist?.strings?.toString()?.split('\n') ?? ['']

    // window.alert(prizes.strings)

    return (<>
        <div className="App-contents">
            <div className="Landing-content">

                <div className="Section-heading">
                    <h4>Leaderboards</h4>
                </div>

                {/* @ts-ignore */}
                <Tabs>
                    {/* @ts-ignore */}
                    <div label="Prizes">

                        <div className="Section-heading">
                            <button onClick={() => setOpenPrizes(true)}>Edit</button>
                        </div>

                        <div className="Blacklist">
                            <ul>
                                {
                                    prizeNames.map(name => <li>{name}</li>)
                                }
                            </ul>
                        </div>

                    </div>
                    {/* @ts-ignore */}
                    <div label="Blacklist">

                        <div className="Section-heading">
                            <button onClick={() => setOpenBlacklist(true)}>Edit</button>
                        </div>

                        <div className="Blacklist">
                            <ul>
                                {
                                    blacklistNames.map(name => <li>{name}</li>)
                                }
                            </ul>
                        </div>

                    </div>
                </Tabs>


                <EditBlacklistModal
                    key={prizes.strings}
                    open={openPrizes}
                    onClose={handleClose}
                    strings={prizes.strings}
                    title={"Edit Leaderboards Prize List"}
                        endpoint={`/moderate/modify-prizes`}
                />

                <EditBlacklistModal
                    key={blacklist.strings}
                    open={openBlacklist}
                    onClose={handleClose}
                    strings={blacklist.strings}
                    title={"Edit Leaderboards Blacklist List"}
                    endpoint={`/moderate/modify-blacklist`}
                />

                <Footer/>
            </div>
        </div>
    </>)
}