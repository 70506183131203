import React from "react";
import TermsOfService from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import FAQ from "./FAQ";

export type FooterProps = {
    // openTos: any
    // openFaq: any
    // openPp: any
};

export const Footer: React.FC<FooterProps> = (props) => {
    const [openTos, setOpenTos] = React.useState(false);
    const [openFaq, setOpenFaq] = React.useState(false);
    const [openPp, setOpenPp] = React.useState(false);


    return (<footer className="App-footer">

        <TermsOfService
            open={openTos}
            onClose={() => setOpenTos(false)}
        />
        <PrivacyPolicy
            open={openPp}
            onClose={() => setOpenPp(false)}
        />
        <FAQ
            open={openFaq}
            onClose={() => setOpenFaq(false)}
        />

        <div className="Footer-Main">
            <div className="Footer-socials">
                <ul></ul>
            </div>
            <div className="Footer-navigation">
                <ul>
                    <li><a onClick={() => setOpenTos(true)}><span>Terms of Service</span></a></li>
                    <li><a onClick={() => setOpenFaq(true)}><span>FAQs</span></a></li>
                    <li><a onClick={() => setOpenPp(true)}><span>Privacy Policy</span></a></li>
                </ul>
            </div>
        </div>
        <div className="Footer-Signature">
            <span>Dragons Den | All Right Reserved 2024.</span>
        </div>
    </footer>);

}