import {Footer} from "../Components/Footer";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {axiosGet} from "../Utility/httpClient";
import {PlayerDetails, ShuffleChallenge, ShuffleChallengers, ShuffleGiveaway} from "../API/api";
import {endDate, numberWithCommas, toRuneScapeFormatFromExact} from "../Utility/utils";
import {Grid} from "@material-ui/core";
import {Card, CardContent, CardMedia, Typography} from "@mui/material";
import TermsOfService from "../Components/TermsOfService";
import PrivacyPolicy from "../Components/PrivacyPolicy";
import FAQ from "../Components/FAQ";

const useStyles = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    card: {
        minWidth: 100,
        maxHeight: 100,
        height: "100%",
        backgroundColor: 'transparent !important',
        margin: '15px',
        border: 'none',
        boxShadow: 'none',
    },
    cardContent: {
        backgroundSize: 'cover',
        backgroundColor: '#080808',
        height: '56px',
        border: '2px solid #2a2e38',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
        background: '#1b1d27 !important'
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
}));

const useStylesGiveaway = makeStyles((theme) => ({
    root: {
        "& > *": {
            margin: theme.spacing(1),
            width: "25ch"
        }
    },
    gridList: {
        width: "100%",
        height: "auto"
    },
    card: {
        maxWidth: '600px',
        marginRight: '2%',
        maxHeight: '410px',
        minWidth: 650,
        height: "410px",
        backgroundColor: 'transparent !important',
        // margin: '25px',
        paddingTop: '15px',
        // border: '1px solid #2a2e38',
        boxShadow: 'none !important',
        display: 'inline-flex',
        borderRadius: '30px !important',
    },
    cardMedia: {
        height: '430px',
        maxWidth: '330px',
        minWidth: '330px',
        padding: '0',
        marginTop: '-20px',
        borderTopLeftRadius: '30px !important',
        borderBottomLeftRadius: '20px',
    },
    cardContent: {
        // background: 'linear-gradient(180deg, #041128, #121418)',
        background: '#2d303d',
        width: '325px',
        height: '410px',
        minHeight: '410px',
        marginTop: '-20px',
        borderTop: '1px solid #2d007d',
        textAlign: 'left',
        fontSize: '11px !important',
        color: '#cecece',
        borderTopRightRadius: '30px !important',
        borderBottomRightRadius: '30px !important',
    },
    select: {
        '&:before': {
            borderColor: 'white !important',
        },
        '&:after': {
            borderColor: 'white !important',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white !important',
        },
        color: 'white !important',
    },
    icon: {
        fill: 'white !important',
    },
    accordion: {
        background: 'linear-gradient(180deg, #041128, #1e1826) !important',
        borderRadius: '10px',
        color: 'white !important',
    },
}));

export type LandingProps = {
    userDetails: PlayerDetails | undefined
};

export type LeaderboardEntry = {
    username: string
    image: string
    totalWagered: number
    prize: number
}


function format(v:number){
    return Number(v.toFixed(2)).toLocaleString('en-US')
}

function startOfNextMonth()
{
    var now = new Date();
    if (now.getMonth() == 11) {
        var current = new Date(now.getFullYear() + 1, 0, 1);
    } else {
        var current = new Date(now.getFullYear(), now.getMonth(), 1);
    }

    // Create a new Date object representing the start of the month by using the year and month of the input date and setting the day to 1
    return new Date(current.getFullYear(), current.getMonth() + 1, 1);
}

const getLeaderboardResetDate = async (): Promise<number> => axiosGet(`/general/leaderboard-reset`)
const getTop3 = async (): Promise<Array<LeaderboardEntry>> => axiosGet(`/general/top-3-leaderboard`);
const getLeaderboard = async (): Promise<Array<LeaderboardEntry>> => axiosGet(`/general/leaderboard`);

const getAllChallenges = async (): Promise<Array<ShuffleChallenge>> => axiosGet(`/general/challenges`)
const getTopChallengers = async (): Promise<Array<ShuffleChallengers>> => axiosGet(`/general/top-3-challengers`)

const getGiveaways = async (): Promise<Array<ShuffleGiveaway>> => axiosGet(`/general/giveaways`)

export const Landing: React.FC<LandingProps> = ({ userDetails }) => {
    const classes = useStyles();

    const [data, setData] = useState<Array<LeaderboardEntry>>([]);
    const [challenges, setChallenges] = useState<Array<ShuffleChallenge>>([]);

    const [time, setTime] = useState<number>(0);
    const [top3, setTop3] = useState<Array<LeaderboardEntry>>([]);
    const [highlightFilter, setHighlightsFilter] = useState<string>('1st Place');
    const [timedKey, setTimedKey] = useState<string>('timed-key-0');
    const [challengers, setChallengers] = useState<Array<ShuffleChallengers>>([]);
    const [giveaways, setGiveaways] = useState<Array<ShuffleGiveaway>>([]);

    useEffect(() => {
        getAllChallenges().then(r => {
            setChallenges(r)
        });
        getTopChallengers().then(r => {
            setChallengers(r)
        });
        getLeaderboardResetDate().then(r => {
            setTime(r - new Date().getTime())
        })
        getTop3().then(response => {
            setTop3(response)
        })
        getLeaderboard().then(response => {
            setData(response)
        })
        getGiveaways().then(r => {
            setGiveaways(r)
        });
    }, [])

    setInterval(() => {
        setTimedKey('timed-key-' + new Date().getTime())
    }, 1000)

    const end = endDate(startOfNextMonth())
    let viewing: LeaderboardEntry | undefined;
    let viewingImg = ''
    if (top3) {
        viewing = highlightFilter == '1st Place' ? top3[0] : highlightFilter == '2nd Place' ? top3[1]: top3[2]
        viewingImg = highlightFilter == '1st Place' ? 'https://dragonsden.gg/gold-sq.png' : highlightFilter == '2nd Place' ? 'https://dragonsden.gg/silver-sq.png': 'https://dragonsden.gg/bronze-sq.png'
    }

    const GiveawayList = (data: Array<ShuffleGiveaway>) => {
        const classes = useStylesGiveaway();
        const [timedKey, setTimedKey] = useState<string>('timed-key-0');

        setInterval(() => {
            setTimedKey('timed-key-' + new Date().getTime())
        }, 1000)

        return (<Grid className={classes.gridList + ' Giveaway-grid'} spacing={0} container>
            {
                data.map((tile) => {
                    const end = endDate(tile.end)
                    return (
                        <Card className={classes.card + ' Giveaway-grid' + ' Giveaway-card'}>
                            <CardMedia
                                component="img"
                                className={classes.cardMedia}
                                image={tile.image}
                            />
                            <CardContent className={classes.cardContent}>
                                <div className="GiveawayContent">
                                    {/*<span>Ends {formatDateYearTime(tile.end)}</span>*/}
                                    <Typography gutterBottom variant="h6" component="div">
                                        <div className="GiveawayHeading">{tile.name}</div>
                                    </Typography>
                                    <ul className="Links">
                                        <li style={{textAlign: 'left', minWidth: '250px'}}><p>{tile.description}</p></li>
                                        {/*<a href={`https://youtube.com/@${tile.youtube}`}><li className="StreamerLi"><img src="https://dragonsden.gg/footer/youtube-icon.svg" width={25} /><p>@{tile.youtube}</p></li></a>*/}
                                        {/*<a href={`https://twitter.com/${tile.twitter}`}><li className="StreamerLi"><img src="https://dragonsden.gg/footer/x-icon.svg" width={20} /><p>/{tile.twitter}</p></li></a>*/}
                                    </ul>
                                    {/*<div className="Watch-req-Timers">*/}
                                    {/*    <ul className="InlineUl">*/}
                                    {/*        <li><img src="https://dragonsden.gg/challenges-img/kick-timer.svg" width={50} /><p className="Watch-req">{tile.kickHours} hrs</p></li>*/}
                                    {/*        <div />*/}
                                    {/*        <li><img src="https://dragonsden.gg/challenges-img/twitch-timer.svg" width={50} /><p className="Watch-req">{tile.twitchHours} hrs</p></li>*/}
                                    {/*    </ul>*/}
                                    {/*</div>*/}
                                    <br /><br />
                                    <p style={{fontSize: '10px', fontWeight: '700', color: 'white'}}>ENDS IN</p>
                                    <div className="Timer">
                                        <ol>
                                            <li><p>{end[0]}</p></li>
                                            <li><p>Days</p></li>
                                        </ol>
                                        <div />
                                        <ol>
                                            <li><p style={{ fontWeight: '700' }}>{end[1]}</p></li>
                                            <li><p>Hours</p></li>
                                        </ol>
                                        <div />
                                        <ol>
                                            <li><p style={{ fontWeight: '700' }}>{end[2]}</p></li>
                                            <li><p>Mins</p></li>
                                        </ol>
                                    </div>
                                    <br/>
                                    <a href={tile.link} target="_action">
                                        <button className="GiveawayBtn" data-ico=" ">
                                            Join Giveaway
                                        </button>
                                    </a>
                                </div>
                            </CardContent>
                        </Card>
                    );
                })}
        </Grid>);
    }

    return (<>
        <div className="App-contents">

            <div className="Landing-content" style={{minWidth: '100%'}}>
                <br /><br /><br /><br />
                <div className="Landing-heading Heading-Landing">
                    <h3 className="Main-heading" style={{fontSize: '120px'}}>Dragon's Den</h3>
                    <div className="Heading-divider"/>
                    {/*<p className="Main-subheading">Welcome to The Dragon's Den.</p>*/}
                    <br /><br /><br /><br />
                    <div className="Leaderboard-btn Small-Large-btn">
                        <a style={{width: '250px', marginTop: '50px'}} href="https://discord.gg/dragonsden">
                            <button style={{borderRadius: '5px'}} className="DiscordBtn" onClick={() => {}}>
                                <img width={35} src="/discord.svg" />
                                Join Discord</button>
                        </a></div>
                    {/*<p className="Main-subheading">World: 308. CC: 'DD Hall'. Hall name: 'Dragons na'</p>*/}
                </div>
                <br/><br/><br/><br/><br/>
                <br/><br/><br/><br/><br/>
                <br/><br/><br/><br/><br/>


                <div className="Landing-heading">
                    <h2 className="Main-heading">Challenges</h2>
                    <div className="Heading-divider Heading-divider-sm"/>
                </div>
                <div className="Landing-Coins">
                    <div className="Gold">
                        {/*<img src="/art/flower4x.png"/>*/}
                    </div>
                    <div className="Silver">
                        <img src="/art/flower4x.png"/>
                    </div>
                </div>
                <div className="Challengers">
                    <div className="Challenger">
                        <div className="Challenger-Image">
                            <img src={'https://dragonsden.gg/gold.png'}/>
                        </div>
                        <div className="Challenger-content Gold">
                            <div className="Challenger-Name">
                                <h3>{challengers[0]?.username.substring(0, 3).concat('...') ?? '-'}</h3>
                            </div>
                            <div className="Challenger-Values">
                                <ul>
                                    <li>Completed</li>
                                    <li><span>{challengers[0]?.completed.toLocaleString('en-US') ?? '-'}</span></li>
                                </ul>
                                <ul>
                                    <li>Earned</li>
                                    <li><span>{challengers[0] ? toRuneScapeFormatFromExact(challengers[0]?.earned ?? 0) : '-'}</span></li>
                                </ul>
                                <ul>
                                    <li>1st Prize</li>
                                    <li><span>{challengers[0]?.prize ?? '-'}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="Challenger">
                        <div className="Challenger-Image">
                            <img src={'https://dragonsden.gg/silver.png'}/>
                        </div>
                        <div className="Challenger-content Silver">
                            <div className="Challenger-Name">
                                <h3>{challengers[1]?.username.substring(0, 3).concat('...') ?? '-'}</h3>
                            </div>
                            <div className="Challenger-Values">
                                <ul>
                                    <li>Completed</li>
                                    <li><span>{challengers[1]?.completed.toLocaleString('en-US') ?? '-'}</span></li>
                                </ul>
                                <ul>
                                    <li>Earned</li>
                                    <li><span>{toRuneScapeFormatFromExact(challengers[1]?.earned ?? 0)}</span></li>
                                </ul>
                                <ul>
                                    <li>2nd Prize</li>
                                    <li><span>{challengers[1]?.prize ?? '-'}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="Challenger">
                        <div className="Challenger-Image">
                            <img src={'https://dragonsden.gg/bronze.png'}/>
                        </div>
                        <div className="Challenger-content Bronze">
                            <div className="Challenger-Name">
                                <h3>{challengers[2]?.username.substring(0, 3) ?? '-'}</h3>
                            </div>
                            <div className="Challenger-Values">
                                <ul>
                                    <li>Completed</li>
                                    <li><span>{challengers[2]?.completed.toLocaleString('en-US') ?? '-'}</span></li>
                                </ul>
                                <ul>
                                    <li>Earned</li>
                                    <li><span>{toRuneScapeFormatFromExact(challengers[2]?.earned ?? 0)}</span></li>
                                </ul>
                                <ul>
                                    <li>3rd Prize</li>
                                    <li><span>{challengers[2]?.prize ?? '-'}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="Timed-challenges-Container">
                        <div className="Timed-challenges ">

                            {
                                challenges.map(challenge => {
                                    const end = endDate(challenge.end)
                                    return (<div className="TimedChallenge">
                                        <div className="Challenge-image"
                                             style={{backgroundImage: "url('" + challenge.image + "')"}}>
                                        </div>
                                        <div className="Challenge-content">
                                            <div className="Challenge-Title">
                                                <span style={{whiteSpace: 'nowrap', fontWeight: 800}}>{challenge.name}</span>
                                            </div>
                                            <div className="Challenge-Info">
                                                <div style={{textAlign: 'left', paddingLeft:'15px', minWidth: '150px', minHeight: '65px', maxHeight: '65px'}}><p dangerouslySetInnerHTML={{__html: challenge.description}} ></p></div>
                                            </div>

                                            <p style={{fontSize: '10px', fontWeight: '700', color: 'white'}}>{challenge.winner ? 'WINNER':'ENDS IN'}</p>
                                            { challenge.winner ? <div className="Challenge-Winner">
                                                <a href="https://discord.com/invite/dragonsden">
                                                    <button>
                                                        <img width={25} src="https://dragonsden.gg/leaderboard-update-white.svg" />
                                                        {challenge.winner}...
                                                    </button>
                                                </a>
                                            </div> : <div className="Timer Challenge-Timer">
                                                <ol>
                                                    <li><p style={{fontWeight: '700'}}>{end[0]}</p></li>
                                                    <li><p>Days</p></li>
                                                </ol>
                                                <div/>
                                                <ol>
                                                    <li><p style={{fontWeight: '700'}}>{end[1]}</p></li>
                                                    <li><p>Hours</p></li>
                                                </ol>
                                                <div/>
                                                <ol>
                                                    <li><p style={{fontWeight: '700'}}>{end[2]}</p></li>
                                                    <li><p>Mins</p></li>
                                                </ol>
                                            </div> }
                                        </div>
                                    </div>)
                                })
                            }

                        </div>
                    </div>
                </div>

                <br/><br/><br/><br/><br/><br/><br/><br/>
                <br/><br/><br/><br/><br/><br/><br/><br/>
                <div className="Landing-heading">
                    <h2 className="Main-heading">Giveaways</h2>
                    <div className="Heading-divider Heading-divider-sm"/>
                </div>
                <br/><br/>


                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <div className="GiveawayList" style={{maxWidth: '1400px'}}>{GiveawayList(giveaways)}</div>
                </div>

                <br/><br/><br/><br/><br/><br/><br/><br/>
                <br/><br/><br/><br/><br/><br/><br/><br/>
                <div className="Landing-heading Heading-Leaderboard">
                    <h2 className="Main-heading" style={{fontSize: '2.9vw', margin: '0', padding: '0'}}>4,000,000,000 GP</h2>
                    <h2 className="Main-heading" style={{margin: '0', padding: '0'}}>Leaderboard</h2>
                    <div className="Heading-divider Heading-divider-sm"/>
                </div>
                <br/><br/><br/><br/>

                <div className="Leaderboard-Pedestal-container">
                    <div className="Leaderboard-Pedestal">
                        <div className="Rankings">
                            <div className="Ranking">
                                <div className="Content Silver">
                                    <div className="User-icon">
                                        <img src={data && data[1] && data[1].image ? data[1].image  : 'https://dragonsden.gg/silver-sq.png'}/>
                                    </div>
                                    <div className="User-info-container User-info-container-silver">
                                        <div className="User-info">
                                            <div className="leaderboard-username">
                                                <span className="Username">{data && data[1] ? data[1].prize : '-'}</span>
                                            </div>
                                            <span>Prize</span>
                                            <h4>{data ? data[1] && toRuneScapeFormatFromExact(data[1].totalWagered) : '-'} GP</h4>
                                            <span>Wagered</span>
                                            <h4 className="Druk">{data ? data[1] && data[1].username : '-'}</h4>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Ranking">
                                <div className="Content Gold">
                                    <div className="User-icon">
                                        <img src={data && data[0] && data[0].image ? data[0].image  : 'https://dragonsden.gg/gold-sq.png'}/>
                                    </div>
                                    <div className="User-info-container User-info-container-gold">
                                        <div className="User-info">
                                            <div className="leaderboard-username">
                                                <span className="Username">{data && data[0] ? data[0].prize : '-'}</span>
                                            </div>
                                            <span>Prize</span>
                                            <h4>{data ? data[0] && toRuneScapeFormatFromExact(data[0].totalWagered) : '-'} GP</h4>
                                            <span>Wagered</span>
                                            <h4 className="Druk">{data ? data[0] && data[0].username : '-'}</h4>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Ranking">
                                <div className="Content Bronze">
                                    <div className="User-icon">
                                        <img src={data && data[2] && data[2].image ? data[2].image  : 'https://dragonsden.gg/bronze-sq.png'}/>
                                    </div>
                                    <div className="User-info-container">
                                        <div className="User-info">
                                            <div className="leaderboard-username">
                                                <span className="Username">{data && data[2] ? data[2].prize : '-'}</span>
                                            </div>
                                            <span>Prize</span>
                                            <h4>{data ? data[2] && toRuneScapeFormatFromExact(data[2].totalWagered) : '-'} GP</h4>
                                            <span>Wagered</span>
                                            <h4 className="Druk">{data ? data[2] && data[2].username : '-'}</h4>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="Leaderboard-Pedestal-container Leaderboard-Pedestal-container-mobile">
                    <br/><br/><br/>
                    <div className="Leaderboard-Pedestal">
                        <div className="Rankings">
                            <div className="Ranking">
                                <div
                                    className={"Content " + (highlightFilter == '1st Place' ? 'Gold' : highlightFilter == '2nd Place' ? 'Silver' : 'Bronze')}>
                                    <div className="User-icon">
                                        {/*<img src={viewing ? viewing.image ?? 'https://dragonsden.gg/newcoin.svg' : 'https://dragonsden.gg/newcoin.svg'}/>*/}
                                        <img src={viewingImg}/>
                                    </div>
                                    <div className="User-info-container User-info-container-gold">
                                        <div className="User-info">
                                            <div className="leaderboard-username">
                                                <span className="Username">{viewing ? viewing.prize : '-'}</span>
                                            </div>
                                            <span>Prize</span>
                                            <h4>{viewing ? toRuneScapeFormatFromExact(viewing.totalWagered) : '-'} GP</h4>
                                            <span>Wagered</span>
                                            <h4 className="Druk">{viewing ?  viewing.username : '-'}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="Pedestal-View-Number">
                    <div onClick={() => setHighlightsFilter('1st Place')} className={`First ${highlightFilter == '1st Place' ? 'First-Active' : ''}`}>1</div>
                    <div onClick={() => setHighlightsFilter('2nd Place')} className={`Second ${highlightFilter == '2nd Place' ? 'Second-Active' : ''}`}>2</div>
                    <div onClick={() => setHighlightsFilter('3rd Place')} className={`Third ${highlightFilter == '3rd Place' ? 'Third-Active' : ''}`}>3</div>
                </div>

                {
                   <div className="Landing-heading Heading-Leaderboard-sub" key={timedKey}>
                        <p className="Main-subheading Leaderboard-subheading"><span>New</span> leaderboard starts in</p>
                        <p className="Main-subheading Leaderboard-subheading"><span>{end[0]} days</span> {end[1]}:{end[2]}:{end[3]}</p>
                    </div>
                }

                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>

                    <div className="Account-settings-container Leaderboard-container Leaderboard-container-Less-padding"
                         style={{width: '800px', maxWidth: '100%', overflowX: 'scroll', overflowY: 'hidden', padding: '0', borderRadius: '5px', border: '3px solid rgba(0, 255, 0, .9)'}}>
                        <ul className="Heading">
                            <li style={{paddingLeft: '10px'}}><span>Rank</span></li>
                            <li><span>User</span></li>
                            <li><span>Wagered</span></li>
                            <li><span>Prize</span></li>
                        </ul>
                        {
                            data.slice(3).map((entry, index) => {
                                return (<ul>
                                    <li><p>{index + 4}</p></li>
                                    <li style={{display: 'flex', justifyContent: 'left'}}>
                                        { entry.image ? <img width={30} style={{borderRadius: '50%', padding: '0 15px'}} src={entry.image} /> : <></> }
                                        {entry.username}
                                    </li>
                                    <li>{toRuneScapeFormatFromExact(entry.totalWagered)} GP</li>
                                    <li>{entry.prize ?? '-'}</li>
                                </ul>)
                            })
                        }
                    </div>
                </div>

                <br /><br /><br /><br /><br /><br />
                <Footer />

            </div>

        </div>
    </>)
}